import { Tokens } from "core/domains/index";

// local storage interface
export const authToken = {
    key: 'token',
    get: function (): string | null {
        const data = localStorage.getItem(authToken.key);
        if (data) {
            return 'Bearer ' + data;

        }
        return null;
    },
    set: (value: Tokens) => {
        localStorage.setItem(authToken.key, JSON.stringify(value));
    },
    remove: () => {
        localStorage.removeItem(authToken.key);
    },
};
