import * as React from 'react';
import './sell-pm.scss';
import { Headline } from 'components/headline/headline';
import { Input } from 'components/input/input';
import { SellVoucherInfo } from 'core/domains/vouchers/voucher';
import { BackArrowIcon, RedAdderssWalletIcon, RedAddIcon, ThreeDotsIcon } from 'assets/icons/svgs/icons';
import { SwiperButton } from 'components/swiper/swiper';
import { NeedSwapDrawer } from 'components/drawer/drawer';
import { title } from 'process';
import { Button } from 'components/button/button';
import { addNewSavedWallet, getMineAddressWallets } from 'core/repositories/wallets/wallet';
import { MineAddressWallets } from 'core/domains';
import { swapPmToCrypto } from 'core/repositories/orders/swap';
import { Empty } from 'components/empty/empty';
import { Spin } from 'antd';


type Props = {

}

export const SellPm: React.FC<Props> = (props) => {

    const [finalDrawer, setFinalDrawer] = React.useState<boolean>(false);

    const [savedWallets, setSavedWallets] = React.useState<MineAddressWallets[]>([]);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [addWalletIsOpen, setAddWalletIsOpen] = React.useState<boolean>(false);

    const [title, setTitle] = React.useState<string>('');

    const [voucher, setVoucher] = React.useState<SellVoucherInfo>({
        voucherCode: '',
        activationCode: '',
        addressWallet: ''
    });


    const clearInputs = () => {
        setTitle('')
        setVoucher({
            voucherCode: '',
            activationCode: '',
            addressWallet: ''
        })
    }
    React.useEffect(() => {
        getMineAddressWallets(setLoading, setSavedWallets)
    }, []);
    return (
        <>
            <NeedSwapDrawer
                isOpen={addWalletIsOpen}
                setIsOpen={setAddWalletIsOpen}
                content={
                    <>
                        {/* finall result of swap */}
                    </>
                }
                title=''
            />
            <NeedSwapDrawer
                isOpen={addWalletIsOpen}
                setIsOpen={setAddWalletIsOpen}
                content={
                    <>
                        <Input
                            name='title'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            type='text'
                            maxLength={20}
                            autoFocus={true}
                            placeholder='عنوان کیف پول'
                            dir={'rtl'}
                        />
                        <Button
                            onClick={() => {
                                addNewSavedWallet(setLoading, setAddWalletIsOpen, voucher.addressWallet, title)
                                var newlist = savedWallets;
                                newlist.push({
                                    label: title,
                                    walletAddress: voucher.addressWallet
                                })
                                setSavedWallets(newlist);
                                getMineAddressWallets(setLoading, setSavedWallets)
                                setTitle('')
                            }}
                            disabled={title === ''}
                            loading={loading}
                            text='افزودن به کیف پول های منتخب'
                            type='swap' />
                    </>
                }
                title=''
            />
            <div className="ns-sell-pm">
                {/* <div onClick={() => setStage('swap')} className="back-line">
                    <span>بازگشت</span>
                    {BackArrowIcon}
                </div> */}
                <div className="coin-info">
                    <h2>تبدیل ارز ووچر به ترون</h2>
                    <p>
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است
                    </p>
                </div>

                <div className="voucher-infos">
                    <Input
                        name='voucherCode'
                        value={voucher.voucherCode}
                        onChange={(e) => setVoucher({ ...voucher, voucherCode: e.target.value })}
                        type='number'
                        maxLength={20}
                        autoFocus={true}
                        placeholder='کد ووچر'
                        dir={voucher.voucherCode === '' ? 'rtl' : 'ltr'}
                    />
                    <Input
                        name='activationCode'
                        value={voucher.activationCode}
                        onChange={(e) => setVoucher({ ...voucher, activationCode: e.target.value })}
                        type='number'
                        maxLength={24}
                        placeholder='کد فعالساز'
                        dir={voucher.activationCode === '' ? 'rtl' : 'ltr'}
                    />
                    <Input
                        name='addressWallet'
                        value={voucher.addressWallet}
                        onChange={(e) => setVoucher({ ...voucher, addressWallet: e.target.value })}
                        type='text'
                        maxLength={100}
                        placeholder='آدرس کیف پول'
                        dir={voucher.addressWallet === '' ? 'rtl' : 'ltr'}
                    />
                    <div
                        onClick={() => voucher.addressWallet && voucher.addressWallet.length > 10 && setAddWalletIsOpen(true)}
                        className={`add-wallet-address ${voucher.addressWallet === '' || voucher.addressWallet.length < 10 ? '' : 'visible'}`}
                    >
                        {RedAddIcon}
                        <span>افزودن به کیف پول‌های منتخب</span>
                    </div>
                </div>
                {
                    loading ? <Spin style={{ "marginTop": "32px" }} spinning={true} /> :
                        savedWallets.length === 0 ? <></> :
                            <div className="mine-selected-addresses">
                                <h4>کیف پول های منتخب</h4>
                                <div className="addresses-list">
                                    {
                                        savedWallets.map((wallet) => {
                                            return <div onClick={() => (setVoucher({ ...voucher, addressWallet: wallet.walletAddress }))} className="recent-address-wallet">
                                                <div className="right-side">
                                                    {RedAdderssWalletIcon}
                                                    <div className="wallet-info">
                                                        <span>{wallet.label}</span>
                                                        <span>{wallet.walletAddress.substring(0, 4).toUpperCase()}...{wallet.walletAddress.substring(wallet.walletAddress.length - 4, wallet.walletAddress.length).toUpperCase()}</span>
                                                    </div>
                                                </div>
                                                <div className="left-side">
                                                    {ThreeDotsIcon}
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                }


                <SwiperButton
                    loading={loading}
                    locked={voucher.voucherCode === '' || voucher.activationCode === '' || voucher.addressWallet === ''}
                    setLoading={setLoading}
                    onConfirm={() => {
                        return swapPmToCrypto(setLoading, setFinalDrawer, voucher.addressWallet, voucher.voucherCode, voucher.activationCode, clearInputs);
                    }}
                />
            </div>
        </>
    );

};
