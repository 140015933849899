export const SettingIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 6.59169V11.4C1.5 13.1667 1.5 13.1667 3.16667 14.2917L7.75 16.9417C8.44167 17.3417 9.56667 17.3417 10.25 16.9417L14.8333 14.2917C16.5 13.1667 16.5 13.1667 16.5 11.4084V6.59169C16.5 4.83336 16.5 4.83336 14.8333 3.70836L10.25 1.05836C9.56667 0.658362 8.44167 0.658362 7.75 1.05836L3.16667 3.70836C1.5 4.83336 1.5 4.83336 1.5 6.59169Z" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9 11.5C10.3807 11.5 11.5 10.3807 11.5 9C11.5 7.61929 10.3807 6.5 9 6.5C7.61929 6.5 6.5 7.61929 6.5 9C6.5 10.3807 7.61929 11.5 9 11.5Z" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>

export const SwapIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.0833 12.4916L12.9083 16.675" stroke="#121212" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2.91669 12.4916H17.0834" stroke="#121212" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2.91669 7.50835L7.09169 3.32501" stroke="#121212" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M17.0834 7.50836H2.91669" stroke="#121212" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
</svg>


export const DepositIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7 7.41669C16.7 7.67502 17.925 9.21669 17.925 12.5917V12.7C17.925 16.425 16.4333 17.9167 12.7083 17.9167H7.28332C3.55832 17.9167 2.06665 16.425 2.06665 12.7V12.5917C2.06665 9.24169 3.27498 7.70002 6.22498 7.42502" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 1.66669V12.4" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.7916 10.5417L9.99998 13.3334L7.20831 10.5417" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>



export const WidthrawlIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7 7.41669C16.7 7.67502 17.925 9.21669 17.925 12.5917V12.7C17.925 16.425 16.4333 17.9167 12.7083 17.9167H7.28332C3.55832 17.9167 2.06665 16.425 2.06665 12.7V12.5917C2.06665 9.24169 3.27498 7.70002 6.22498 7.42502" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 12.5V3.01666" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.7916 4.87498L9.99998 2.08331L7.20831 4.87498" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>


export const HistoryIcon = <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.3 12C22.3 17.52 17.82 22 12.3 22C6.78002 22 2.30002 17.52 2.30002 12C2.30002 6.48 6.78002 2 12.3 2C17.82 2 22.3 6.48 22.3 12Z" stroke="#909090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16.01 15.18L12.91 13.33C12.37 13.01 11.93 12.24 11.93 11.61V7.51001" stroke="#909090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>

export const ExchangeIcon = <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7 9H7.70001" stroke="#909090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22.7 10.9699V13.03C22.7 13.58 22.26 14.0299 21.7 14.0499H19.74C18.66 14.0499 17.67 13.2599 17.58 12.1799C17.52 11.5499 17.76 10.9599 18.18 10.5499C18.55 10.1699 19.06 9.94995 19.62 9.94995H21.7C22.26 9.96995 22.7 10.4199 22.7 10.9699Z" stroke="#909090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.18 10.55C17.76 10.96 17.52 11.55 17.58 12.18C17.67 13.26 18.66 14.05 19.74 14.05H21.7V15.5C21.7 18.5 19.7 20.5 16.7 20.5H7.70001C4.70001 20.5 2.70001 18.5 2.70001 15.5V8.5C2.70001 5.78 4.34001 3.88 6.89001 3.56C7.15001 3.52 7.42001 3.5 7.70001 3.5H16.7C16.96 3.5 17.21 3.50999 17.45 3.54999C20.03 3.84999 21.7 5.76 21.7 8.5V9.95001H19.62C19.06 9.95001 18.55 10.17 18.18 10.55Z" stroke="#909090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>


export const GameIcon = <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.47 12.46L7.41998 15.51" stroke="#464646" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.44998 12.49L10.5 15.54" stroke="#464646" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.43 14H14.44" stroke="#464646" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.37 14H18.38" stroke="#464646" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16.4 15.98V15.96" stroke="#464646" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16.4 12.04V12.02" stroke="#464646" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.89999 22H15.9C20.9 22 22.9 20 22.9 15V13C22.9 8 20.9 6 15.9 6H9.89999C4.89999 6 2.89999 8 2.89999 13V15C2.89999 20 4.89999 22 9.89999 22Z" stroke="#464646" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.91 2L13.9 3.01C13.89 3.56 13.45 4 12.9 4H12.87C12.32 4 11.88 4.45 11.88 5C11.88 5.55 12.33 6 12.88 6H13.88" stroke="#464646" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>

export const ProfileIcon = <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.24 21.62C17.36 21.88 16.32 22 15.1 22H9.09996C7.87996 22 6.83996 21.88 5.95996 21.62C6.17996 19.02 8.84996 16.97 12.1 16.97C15.35 16.97 18.02 19.02 18.24 21.62Z" stroke="#909090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.1 2H9.09998C4.09998 2 2.09998 4 2.09998 9V15C2.09998 18.78 3.23998 20.85 5.95998 21.62C6.17998 19.02 8.84998 16.97 12.1 16.97C15.35 16.97 18.02 19.02 18.24 21.62C20.96 20.85 22.1 18.78 22.1 15V9C22.1 4 20.1 2 15.1 2ZM12.1 14.17C10.12 14.17 8.51997 12.56 8.51997 10.58C8.51997 8.60002 10.12 7 12.1 7C14.08 7 15.68 8.60002 15.68 10.58C15.68 12.56 14.08 14.17 12.1 14.17Z" stroke="#909090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.68 10.58C15.68 12.56 14.08 14.17 12.1 14.17C10.12 14.17 8.52002 12.56 8.52002 10.58C8.52002 8.60002 10.12 7 12.1 7C14.08 7 15.68 8.60002 15.68 10.58Z" stroke="#909090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>


export const HomeIcon = <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5 17V14" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.57002 1.81997L2.64002 7.36997C1.86002 7.98997 1.36002 9.29997 1.53002 10.28L2.86002 18.24C3.10002 19.66 4.46002 20.81 5.90002 20.81H17.1C18.53 20.81 19.9 19.65 20.14 18.24L21.47 10.28C21.63 9.29997 21.13 7.98997 20.36 7.36997L13.43 1.82997C12.36 0.969971 10.63 0.969971 9.57002 1.81997Z" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>


export const InfoIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_121_2844)">
        <path d="M7.99999 7.33331V11.3333M14.6667 7.99998C14.6667 11.6819 11.6819 14.6666 7.99999 14.6666C4.3181 14.6666 1.33333 11.6819 1.33333 7.99998C1.33333 4.31808 4.3181 1.33331 7.99999 1.33331C11.6819 1.33331 14.6667 4.31808 14.6667 7.99998Z" stroke="#707070" stroke-linecap="round" />
        <path d="M8.66666 5.33329C8.66666 5.70148 8.36818 5.99996 7.99999 5.99996C7.63181 5.99996 7.33333 5.70148 7.33333 5.33329C7.33333 4.9651 7.63181 4.66663 7.99999 4.66663C8.36818 4.66663 8.66666 4.9651 8.66666 5.33329Z" fill="#707070" />
    </g>
    <defs>
        <clipPath id="clip0_121_2844">
            <rect width="16" height="16" fill="white" />
        </clipPath>
    </defs>
</svg>

export const SwapBoxIcon = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="15.5" transform="rotate(-90 16 16)" fill="#1B1B1B" stroke="#363636" />
    <path d="M18.2425 9.62501L22.0075 13.3825" stroke="#999999" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.2425 22.375V9.625" stroke="#999999" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.7575 22.375L9.99249 18.6175" stroke="#999999" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.7575 9.625V22.375" stroke="#999999" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
</svg>

export const SwapIconLight = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="white" fill-opacity="0.3" />
    <path d="M21.6667 17.9933L18.3267 21.34" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.3334 17.9933H21.6667" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.3334 14.0066L13.6734 10.66" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21.6667 14.0067H10.3334" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
</svg>




export const WidthrawlIconLight = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="white" fill-opacity="0.3" />
    <path d="M18.96 13.9333C21.36 14.14 22.34 15.3733 22.34 18.0733V18.16C22.34 21.14 21.1467 22.3333 18.1667 22.3333H13.8267C10.8467 22.3333 9.65332 21.14 9.65332 18.16V18.0733C9.65332 15.3933 10.62 14.16 12.98 13.94" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16 18V10.4133" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.2334 11.9L16.0001 9.66663L13.7667 11.9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>


export const ShareIcon = <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.91998 7.45334V0.67334" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M0.666687 6C0.666687 8.94667 2.66669 11.3333 6.00002 11.3333C9.33335 11.3333 11.3334 8.94667 11.3334 6" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
</svg>




export const DepositIconLight = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="white" fill-opacity="0.3" />
    <path d="M18.96 13.9333C21.36 14.14 22.34 15.3733 22.34 18.0733V18.16C22.34 21.14 21.1467 22.3333 18.1667 22.3333H13.8267C10.8467 22.3333 9.65332 21.14 9.65332 18.16V18.0733C9.65332 15.3933 10.62 14.16 12.98 13.94" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16 9.33331V17.92" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.2334 16.4333L16.0001 18.6667L13.7667 16.4333" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>


export const CopyToClipboardIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6666 8.60004V11.4C10.6666 13.7334 9.73331 14.6667 7.39998 14.6667H4.59998C2.26665 14.6667 1.33331 13.7334 1.33331 11.4V8.60004C1.33331 6.26671 2.26665 5.33337 4.59998 5.33337H7.39998C9.73331 5.33337 10.6666 6.26671 10.6666 8.60004Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.6666 4.60004V7.40004C14.6666 9.73337 13.7333 10.6667 11.4 10.6667H10.6666V8.60004C10.6666 6.26671 9.73331 5.33337 7.39998 5.33337H5.33331V4.60004C5.33331 2.26671 6.26665 1.33337 8.59998 1.33337H11.4C13.7333 1.33337 14.6666 2.26671 14.6666 4.60004Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
</svg>

export const ThreeDotsIcon = <svg width="12" height="4" viewBox="0 0 12 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.33333 0.666626C0.6 0.666626 0 1.26663 0 1.99996C0 2.73329 0.6 3.33329 1.33333 3.33329C2.06667 3.33329 2.66667 2.73329 2.66667 1.99996C2.66667 1.26663 2.06667 0.666626 1.33333 0.666626Z" fill="#D9D9D9" />
    <path d="M10.6666 0.666626C9.93331 0.666626 9.33331 1.26663 9.33331 1.99996C9.33331 2.73329 9.93331 3.33329 10.6666 3.33329C11.4 3.33329 12 2.73329 12 1.99996C12 1.26663 11.4 0.666626 10.6666 0.666626Z" fill="#D9D9D9" />
    <path d="M6.00002 0.666626C5.26669 0.666626 4.66669 1.26663 4.66669 1.99996C4.66669 2.73329 5.26669 3.33329 6.00002 3.33329C6.73335 3.33329 7.33335 2.73329 7.33335 1.99996C7.33335 1.26663 6.73335 0.666626 6.00002 0.666626Z" fill="#D9D9D9" />
</svg>

export const ScanIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.66669 7.50002V5.83335C1.66669 3.33335 3.33335 1.66669 5.83335 1.66669H14.1667C16.6667 1.66669 18.3334 3.33335 18.3334 5.83335V7.50002" stroke="white" stroke-opacity="0.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.66669 12.5V14.1667C1.66669 16.6667 3.33335 18.3333 5.83335 18.3333H14.1667C16.6667 18.3333 18.3334 16.6667 18.3334 14.1667V12.5" stroke="white" stroke-opacity="0.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.66669 10H18.3334" stroke="white" stroke-opacity="0.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
</svg>

export const BlueScanIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.33331 5.99998V4.66665C1.33331 2.66665 2.66665 1.33331 4.66665 1.33331H11.3333C13.3333 1.33331 14.6666 2.66665 14.6666 4.66665V5.99998" stroke="#0788FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.33331 10V11.3333C1.33331 13.3333 2.66665 14.6667 4.66665 14.6667H11.3333C13.3333 14.6667 14.6666 13.3333 14.6666 11.3333V10" stroke="#0788FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.33331 8H14.6666" stroke="#0788FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
</svg>


export const SwiperArrowsIcon = <svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.4063 10.5051C3.63245 10.7501 3.99707 10.7501 4.22322 10.5051L8.0586 6.35009C8.2386 6.15509 8.2386 5.84009 8.0586 5.64509L4.22322 1.49009C3.99707 1.24509 3.63245 1.24509 3.4063 1.49009C3.18014 1.73509 3.18014 2.13009 3.4063 2.37509L4.87222 3.96537C5.93167 5.1147 5.93107 6.88493 4.87082 8.03353L3.40168 9.62509C3.18014 9.86509 3.18014 10.2651 3.4063 10.5051Z" fill="url(#paint0_linear_121_2801)" />
    <path d="M9.86784 10.5051C10.094 10.7501 10.4586 10.7501 10.6848 10.5051L14.5202 6.35009C14.7002 6.15509 14.7002 5.84009 14.5202 5.64509L10.6848 1.49009C10.4586 1.24509 10.094 1.24509 9.86785 1.49009C9.64169 1.73509 9.64169 2.13009 9.86785 2.37509L11.3338 3.96537C12.3932 5.1147 12.3926 6.88493 11.3324 8.03353L9.86323 9.62509C9.64169 9.86509 9.64169 10.2651 9.86784 10.5051Z" fill="url(#paint1_linear_121_2801)" />
    <path d="M16.3293 10.5051C16.5554 10.7501 16.92 10.7501 17.1462 10.5051L20.9816 6.35009C21.1616 6.15509 21.1616 5.84009 20.9816 5.64509L17.1462 1.49009C16.92 1.24509 16.5554 1.24509 16.3293 1.49009C16.1031 1.73509 16.1031 2.13009 16.3293 2.37509L17.7952 3.96537C18.8546 5.1147 18.854 6.88493 17.7938 8.03353L16.3247 9.62509C16.1031 9.86509 16.1031 10.2651 16.3293 10.5051Z" fill="url(#paint2_linear_121_2801)" />
    <defs>
        <linearGradient id="paint0_linear_121_2801" x1="0" y1="0" x2="1" y2="1" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stop-color="#09e485" />
            <stop offset="100%" stop-color="#0291e3" />
        </linearGradient>
        <linearGradient id="paint1_linear_121_2801" x1="0" y1="0" x2="1" y2="1" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stop-color="#09e485" />
            <stop offset="100%" stop-color="#0291e3" />
        </linearGradient>
        <linearGradient id="paint2_linear_121_2801" x1="0" y1="0" x2="1" y2="1" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stop-color="#09e485" />
            <stop offset="100%" stop-color="#0291e3" />
        </linearGradient>
    </defs>
</svg>

export const EmptyIcon = <svg width="227" height="149" viewBox="0 0 227 149" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_379_2620)">
        <path d="M223.064 35.13L219.514 52.3495L217.229 50.12L213.341 52.8957L210.37 49.2021L203.708 54.0551L198.014 44.5164L191.663 48.2434L189.144 44.7616L181.08 49.3136L178.654 44.1114L184.307 16.6843L214.614 22.953L223.064 35.13Z" fill="#262626" />
        <path d="M214.614 22.953L223.064 35.13L212.551 32.9562L214.614 22.953Z" fill="#4C4C4C" />
        <path d="M218.757 55.7941L213.553 81.0585L172.73 72.6197L177.897 47.5522L180.323 52.7582L188.391 48.2062L190.906 51.6843L197.261 47.9573L202.951 57.496L209.617 52.643L212.585 56.3366L216.476 53.5608L218.757 55.7941Z" fill="#262626" />
        <path d="M160.329 52.8326L118.727 55.4114L115.422 1.91369L146.314 0L157.654 9.52756L160.329 52.8326Z" fill="#262626" />
        <path d="M146.314 0L157.654 9.52756L146.941 10.1927L146.314 0Z" fill="#4C4C4C" />
        <path d="M134.789 35.9958L134.626 34.7882C134.203 32.2874 134.882 29.4893 137.048 26.5612C138.999 23.9712 140.06 22.0872 139.941 19.9878C139.804 17.6096 138.228 16.1121 135.293 16.2347C133.617 16.3313 131.788 16.9965 130.679 17.9477L129.395 15.0754C130.868 13.8677 133.476 12.9722 135.943 12.8309C141.292 12.5225 143.899 15.6959 144.103 19.2372C144.285 22.4068 142.646 24.7924 140.565 27.5756C138.658 30.1173 138.027 32.2093 138.306 34.5801L138.421 35.7878L134.793 35.9958H134.789ZM134.14 42.5804C134.04 40.8562 135.134 39.578 136.762 39.4851C138.391 39.3922 139.578 40.5404 139.674 42.2646C139.767 43.8958 138.769 45.215 137.048 45.3153C135.42 45.4082 134.232 44.2154 134.14 42.5841V42.5804Z" fill="#4C4C4C" />
        <path d="M30.7369 26.8957L26.5489 24.9932L25.9702 26.2789C25.7996 26.6579 25.4286 26.8957 25.0169 26.9143C24.7869 26.9254 24.5606 26.9477 24.3343 26.9812C23.9263 27.0443 23.5182 26.8771 23.2771 26.5427L22.4573 25.3982L18.7181 28.0811L19.5379 29.2256C19.779 29.56 19.8013 30.0022 19.6121 30.3701C19.5045 30.5744 19.4118 30.7825 19.3302 30.9981C19.1818 31.3845 18.8331 31.6595 18.4214 31.6966L17.0192 31.8341L17.4717 36.4158L18.8776 36.2746C19.2894 36.2337 19.6789 36.4381 19.9052 36.7874C19.9645 36.884 20.0276 36.9769 20.0944 37.0698C20.1611 37.1627 20.2279 37.2519 20.2984 37.3411C20.5543 37.6644 20.6248 38.0991 20.4542 38.4744L19.8681 39.7676L24.0561 41.6701L24.6385 40.3844C24.8091 40.0054 25.1801 39.7676 25.5919 39.749C25.8181 39.7378 26.0481 39.7155 26.2744 39.6821C26.6824 39.6189 27.0905 39.7861 27.3316 40.1206L28.1514 41.2651L31.8906 38.5822L31.0708 37.4377C30.8297 37.1033 30.8074 36.6611 30.9966 36.2932C31.1005 36.0888 31.1969 35.8807 31.2785 35.6689C31.4269 35.2825 31.7756 35.0075 32.1874 34.9666L33.5896 34.8291L33.137 30.2474L31.7311 30.3849C31.3193 30.4258 30.9336 30.2214 30.7073 29.8721C30.6479 29.7755 30.5811 29.6826 30.5144 29.5897C30.4476 29.4968 30.3808 29.4076 30.3104 29.3185C30.0507 28.9952 29.9839 28.5567 30.1546 28.1814L30.7369 26.8957ZM28.3629 31.1355C29.5722 32.83 29.1864 35.1821 27.4948 36.3972C25.8033 37.6086 23.4515 37.2222 22.2385 35.5314C21.0291 33.837 21.4149 31.4774 23.1065 30.266C24.798 29.0546 27.1498 29.4448 28.3591 31.1393L28.3629 31.1355Z" fill="#262626" />
        <path d="M219.885 87.6877L217.096 89.3004L217.589 90.1551C217.734 90.4078 217.719 90.7162 217.563 90.9577C217.474 91.0915 217.396 91.2327 217.322 91.3739C217.192 91.6303 216.929 91.7975 216.643 91.7975H215.656V95.0229H216.643C216.932 95.0229 217.192 95.1901 217.322 95.4465C217.396 95.5914 217.474 95.7289 217.563 95.8627C217.722 96.1042 217.734 96.4164 217.589 96.6653L217.096 97.52L219.885 99.1327L220.378 98.2743C220.523 98.0216 220.798 97.8841 221.091 97.899C221.169 97.9027 221.25 97.9064 221.328 97.9064C221.406 97.9064 221.488 97.9064 221.566 97.899C221.855 97.8841 222.129 98.0216 222.274 98.2706L222.771 99.1327L225.561 97.52L225.067 96.6616C224.923 96.4089 224.937 96.1005 225.097 95.859C225.186 95.7252 225.264 95.5877 225.338 95.4428C225.468 95.1864 225.728 95.0192 226.017 95.0192H227.004V91.7938H226.017C225.728 91.7938 225.468 91.6266 225.338 91.3702C225.264 91.2253 225.186 91.0878 225.097 90.954C224.937 90.7125 224.923 90.4003 225.067 90.1514L225.561 89.293L222.771 87.6803L222.278 88.5387C222.133 88.7913 221.859 88.9288 221.569 88.914C221.491 88.9103 221.41 88.9065 221.332 88.9065C221.254 88.9065 221.172 88.9065 221.094 88.914C220.805 88.9288 220.527 88.7913 220.382 88.5387L219.889 87.6803L219.885 87.6877ZM221.328 90.7719C222.786 90.7719 223.966 91.9536 223.966 93.4139C223.966 94.8743 222.786 96.0559 221.328 96.0559C219.87 96.0559 218.691 94.8706 218.691 93.4139C218.691 91.9573 219.874 90.7719 221.328 90.7719Z" fill="#262626" />
        <path d="M39.2021 13.1654L36.6425 15.1237L37.2435 15.9077C37.4215 16.1381 37.4475 16.4465 37.3214 16.7104C37.2509 16.8553 37.1916 17.0039 37.1359 17.1563C37.0395 17.4275 36.8021 17.6282 36.5164 17.6654L35.5371 17.7917L35.9526 20.9911L36.9319 20.8647C37.2175 20.8276 37.4994 20.9576 37.6627 21.1992C37.7554 21.333 37.8518 21.4593 37.9557 21.5819C38.1449 21.8012 38.1968 22.1059 38.0855 22.3771L37.7072 23.2875L40.6822 24.5249L41.0606 23.6108C41.1719 23.3433 41.4278 23.1686 41.7172 23.1463C41.7951 23.1389 41.873 23.1314 41.9546 23.1203C42.0325 23.1091 42.1104 23.098 42.1883 23.0831C42.4739 23.0311 42.7632 23.1314 42.9376 23.3618L43.5422 24.1533L46.1018 22.195L45.5009 21.4073C45.3265 21.1769 45.3005 20.8685 45.4267 20.6046C45.4972 20.4597 45.5565 20.3111 45.6121 20.1624C45.7086 19.8912 45.946 19.6905 46.2316 19.6534L47.2109 19.527L46.7955 16.3276L45.8162 16.454C45.5305 16.4911 45.2486 16.3611 45.0854 16.1233C44.9964 15.9895 44.8962 15.8632 44.7923 15.7405C44.6032 15.5213 44.5512 15.2166 44.6625 14.9453L45.0409 14.0312L42.0659 12.7938L41.6875 13.7079C41.5762 13.9755 41.3202 14.1464 41.0346 14.1724C40.9567 14.1799 40.8751 14.1873 40.7972 14.1984C40.7193 14.2096 40.6414 14.2207 40.5635 14.2356C40.2779 14.2876 39.9848 14.1873 39.8105 13.9569L39.2095 13.1728L39.2021 13.1654ZM41.0272 16.0378C42.4702 15.8483 43.7945 16.8702 43.9837 18.3156C44.1729 19.7611 43.1527 21.0914 41.7097 21.2772C40.2667 21.4667 38.9387 20.4449 38.7533 18.9957C38.5641 17.5502 39.5842 16.2236 41.0309 16.0341L41.0272 16.0378Z" fill="#262626" />
        <path d="M20.1056 97.0035C20.2503 93.4065 20.1946 89.8058 19.9572 86.2163C19.4713 78.8105 18.2546 71.4604 16.5816 64.2367C15.6394 60.1678 14.482 56.1695 13.2764 52.1749C13.2393 52.056 13.0464 52.0895 13.0798 52.2158C16.8116 66.2991 19.8756 80.8282 19.6642 95.4688C19.6048 99.5638 19.13 103.588 18.7442 107.661C18.7331 107.768 18.9111 107.813 18.9371 107.702C19.7087 104.216 19.9572 100.567 20.1019 97.0109L20.1056 97.0035Z" fill="#262626" />
        <path d="M13.0798 52.212C13.0798 52.212 7.42275 66.5926 8.6914 69.9703C9.95635 73.3518 12.5678 74.0727 12.5678 74.0727C12.5678 74.0727 9.10687 79.0222 11.5255 83.6634C13.9441 88.3045 19.3154 88.0853 19.6716 93.347C19.6716 93.347 19.972 71.3415 13.0798 52.212Z" fill="#262626" />
        <path d="M19.0595 84.2617C19.0669 84.2395 19.0632 84.2134 19.0521 84.1874C18.2583 74.4109 16.5259 64.6641 13.5657 55.2592C13.5583 55.2332 13.5101 55.2406 13.5175 55.2703C14.6378 59.4767 15.6356 63.7054 16.4888 67.9564C15.7543 67.1984 14.7936 66.7636 13.7883 66.4478C13.7698 66.4403 13.7586 66.47 13.7772 66.4775C14.8233 66.9308 15.8248 67.4548 16.563 68.3429C16.7299 69.1752 16.8895 70.0113 17.0453 70.8437C15.7803 69.4279 14.0294 68.7256 12.2748 68.0604C12.2489 68.0493 12.2377 68.0902 12.26 68.0976C14.0851 68.8408 15.9064 69.8032 17.1454 71.3787C17.5757 73.7643 17.9541 76.1611 18.2805 78.5616C17.3569 77.5954 16.218 76.9452 14.9086 76.6256C14.8678 76.6144 14.8455 76.6739 14.8863 76.685C16.2292 77.0715 17.3865 77.8258 18.3065 78.8811C18.3139 78.8886 18.3213 78.8923 18.3287 78.896C18.4437 79.7395 18.5476 80.5867 18.6478 81.434C17.9355 80.8245 17.1491 80.3638 16.2848 80.007C16.2625 79.9959 16.2477 80.0331 16.2737 80.0442C17.1528 80.4195 17.943 80.9583 18.6403 81.6123C18.6515 81.6198 18.6589 81.6198 18.67 81.6235C18.7628 82.4298 18.8592 83.2324 18.9408 84.0425C17.6425 82.4781 16.04 81.3448 14.1444 80.6127C14.1073 80.5979 14.0888 80.6536 14.1222 80.6722C15.9658 81.4748 17.5906 82.7494 18.8481 84.3175C18.8815 84.3621 18.926 84.3658 18.9668 84.3546C19.0892 85.592 19.1968 86.8332 19.2895 88.0743C19.2895 88.0928 19.3192 88.0928 19.3192 88.0743C19.2487 86.8034 19.1597 85.5326 19.0558 84.2617H19.0595Z" fill="#262626" />
        <path d="M14.5154 65.0171C13.5361 64.3408 12.2897 64.0547 11.1175 63.9543C11.0878 63.9543 11.0878 63.9952 11.1175 63.9989C12.3342 64.181 13.358 64.6232 14.4783 65.0988C14.5265 65.1211 14.5599 65.0468 14.5191 65.0171H14.5154Z" fill="#262626" />
        <path d="M15.2015 80.2448C14.4263 79.7766 13.5397 79.5127 12.6494 79.3715C12.6197 79.3678 12.616 79.4161 12.6494 79.4198C13.5508 79.5833 14.3521 79.9066 15.1719 80.3079C15.2053 80.3265 15.2349 80.2671 15.2015 80.2485V80.2448Z" fill="#262626" />
        <path d="M34.9733 70.2044C32.614 71.8654 30.3512 73.7828 28.426 75.938C26.3746 78.2344 24.8797 80.843 23.8336 83.7377C21.6932 89.6682 20.521 96.0893 20.4135 102.391C20.3541 105.925 20.6657 109.429 21.4224 112.881C21.4484 113.008 21.6376 112.971 21.619 112.844C20.6954 106.769 20.7288 100.571 21.7229 94.5101C22.6948 88.5609 24.2194 82.0878 27.9957 77.2534C30.0508 74.6226 32.614 72.5491 35.1476 70.4088C35.2812 70.2973 35.1106 70.1115 34.9733 70.2081V70.2044Z" fill="#262626" />
        <path d="M21.2481 98.1071C21.2481 98.1071 23.1919 94.8297 26.0185 91.9982C28.8489 89.1667 31.2304 87.1526 31.5976 85.2761C31.9649 83.4033 28.7636 82.021 28.7636 82.021C28.7636 82.021 32.6771 82.7233 33.7492 81.0809C34.8212 79.4347 35.3368 69.9034 35.3368 69.9034C35.3368 69.9034 28.2146 74.9979 24.8945 82.1399C21.5745 89.2818 21.2444 98.1108 21.2444 98.1108L21.2481 98.1071Z" fill="#262626" />
        <path d="M32.8774 72.3448C29.8801 75.2952 26.8643 78.4351 25.0985 82.3108C24.4939 83.6374 23.9783 85.016 23.5257 86.4206C23.5257 86.4206 23.5257 86.4243 23.5257 86.428C23.433 86.7104 23.3439 86.9891 23.2549 87.2753C23.2475 87.2901 23.2401 87.305 23.2401 87.3199C22.3683 90.2145 21.7526 93.2058 21.2184 96.1265C21.2184 96.1451 21.2443 96.1563 21.2481 96.1377C21.5559 94.6513 21.8935 93.1501 22.2719 91.6526C22.2867 91.6526 22.2979 91.6526 22.3127 91.6452C22.9173 91.3888 23.5294 91.229 24.1823 91.1695C24.2231 91.1658 24.2231 91.0915 24.1786 91.0952C23.548 91.1249 22.9285 91.2364 22.3312 91.4334C22.6762 90.0845 23.0509 88.7356 23.4738 87.409C24.85 87.0709 26.2856 86.8888 27.6989 86.8554C27.7137 86.8554 27.7175 86.8331 27.6989 86.8331C26.2745 86.7662 24.9353 86.8888 23.5442 87.1861C23.6184 86.9594 23.6926 86.7327 23.7705 86.5061C24.6979 86.2943 25.6327 86.1159 26.5823 86.0304C26.6009 86.0304 26.5972 86.0007 26.5823 86.0007C25.6513 86.0267 24.735 86.1233 23.8299 86.3351C24.2157 85.1869 24.6348 84.0536 25.1022 82.95C25.488 82.0359 25.9443 81.17 26.4488 80.334C27.2092 80.163 27.9474 79.9958 28.7301 79.9252C28.7524 79.9252 28.7487 79.8881 28.7301 79.8881C28.0216 79.8435 27.2834 79.8955 26.5972 80.0999C26.9718 79.4942 27.365 78.8996 27.7879 78.3237C29.3867 77.9707 31.0041 77.6399 32.6363 77.9484C32.6659 77.9521 32.6734 77.9075 32.6474 77.9001C31.0931 77.4913 29.55 77.7254 27.9994 78.0338C28.17 77.8072 28.3407 77.5879 28.515 77.365C29.4943 77.0603 30.4699 76.9153 31.5012 76.8968C31.5234 76.8968 31.5271 76.867 31.5012 76.8633C30.5626 76.7741 29.6241 76.8633 28.719 77.1123C30.0396 75.4699 31.4974 73.9241 32.9404 72.3968C33.0072 72.3262 32.9553 72.2853 32.8848 72.3559L32.8774 72.3448Z" fill="#262626" />
        <path d="M32.7958 78.8401C32.3284 78.7918 31.8499 78.7361 31.3788 78.7918C31.3231 78.7993 31.3269 78.8922 31.3825 78.8922C31.8499 78.907 32.321 78.8699 32.7884 78.8959C32.8255 78.8959 32.8292 78.8439 32.7921 78.8401H32.7958Z" fill="#262626" />
        <path d="M27.6693 88.0891C26.92 88.0816 26.1744 88.2823 25.4733 88.5313C25.4547 88.5387 25.4659 88.5684 25.4844 88.561C26.2078 88.3863 26.9422 88.3195 27.6767 88.1968C27.7361 88.1857 27.7324 88.0891 27.6693 88.0891Z" fill="#262626" />
        <path d="M26.1335 84.8933C25.8293 84.8785 25.5029 84.9305 25.2024 84.9788C25.1728 84.9825 25.1728 85.0345 25.2024 85.0345C25.5066 85.0382 25.8368 85.0494 26.1372 84.9899C26.1892 84.9788 26.1892 84.897 26.1335 84.8933Z" fill="#262626" />
        <path d="M0.367223 80.3229C2.6597 81.1218 4.93363 82.1474 6.98871 83.4442C9.17732 84.8303 10.9913 86.5916 12.5048 88.6911C15.6059 92.9941 18.0356 97.9214 19.5751 102.994C20.4394 105.84 20.9921 108.746 21.1813 111.715C21.1887 111.823 21.0292 111.837 21.0144 111.73C20.3578 106.602 18.9 101.593 16.7002 96.9143C14.5413 92.3252 11.8111 87.4425 7.64158 84.4029C5.37136 82.7494 2.81921 81.6643 0.274485 80.5198C0.140943 80.4604 0.23739 80.2709 0.370932 80.3154L0.367223 80.3229Z" fill="#262626" />
        <path d="M17.9132 99.7236C17.9132 99.7236 15.5836 97.52 12.642 95.885C9.70033 94.2463 7.30399 93.1687 6.57692 91.7381C5.84615 90.3075 8.12008 88.4495 8.12008 88.4495C8.12008 88.4495 5.11537 89.9247 3.86898 88.8434C2.62258 87.7584 0.00366211 80.1631 0.00366211 80.1631C0.00366211 80.1631 6.94416 82.6379 11.2769 87.6469C15.6096 92.6559 17.9169 99.7273 17.9169 99.7273L17.9132 99.7236Z" fill="#262626" />
        <path d="M17.5496 98.1554C14.0775 91.6303 9.44805 85.7257 3.10108 81.8278C3.10108 81.8278 3.09366 81.8278 3.10108 81.8315C4.77036 82.8979 6.30981 84.1279 7.74539 85.4731C6.4619 85.4359 5.15244 85.644 4.01362 86.2608C3.98765 86.2757 4.0062 86.3091 4.03216 86.3017C5.30082 85.8707 6.52125 85.5882 7.87151 85.6514C7.89006 85.6514 7.90119 85.644 7.91603 85.6366C8.14231 85.8521 8.36488 86.0676 8.58374 86.2868C7.91603 86.3166 7.24089 86.4095 6.59173 86.5507C6.57318 86.5544 6.5806 86.5804 6.59544 86.5767C7.30025 86.4392 7.98651 86.3946 8.70244 86.402C9.86723 87.5725 10.9504 88.8248 11.9742 90.1253C11.5105 90.0585 11.0246 90.0956 10.5795 90.2257C10.5461 90.2368 10.5535 90.2814 10.5906 90.2777C11.0951 90.2145 11.5773 90.222 12.0781 90.2554C12.2673 90.4969 12.4602 90.7385 12.6457 90.9837C11.2175 90.8983 9.81901 91.0357 8.43536 91.3925C8.4131 91.3962 8.42052 91.4296 8.44278 91.4259C9.87465 91.1435 11.3139 91.0543 12.7681 91.1435C12.9461 91.3776 13.1131 91.6191 13.2874 91.857C12.4342 91.8309 11.6107 91.9387 10.7798 92.1431C10.7464 92.1505 10.7575 92.1988 10.7909 92.1951C11.6441 92.0019 12.5121 91.9499 13.3876 91.9907C14.1332 93.02 14.8491 94.0754 15.5354 95.1418C15.1718 95.0675 14.7898 95.1084 14.4336 95.2087C14.4151 95.2124 14.4188 95.2421 14.4411 95.2384C14.8491 95.1901 15.2312 95.2421 15.6318 95.2942C16.2513 96.2566 16.8485 97.2301 17.4198 98.2111C17.468 98.2929 17.6016 98.2334 17.5571 98.1443L17.5496 98.1554Z" fill="#262626" />
        <path d="M12.1523 92.7451C11.4734 92.6856 10.7835 92.7934 10.1343 92.9903C10.1158 92.9978 10.1195 93.0238 10.1417 93.0201C10.8094 92.8974 11.4697 92.8491 12.1449 92.8157C12.1894 92.8157 12.1968 92.7488 12.1486 92.7451H12.1523Z" fill="#262626" />
        <path d="M6.09837 84.7894C5.79048 84.7559 5.47146 84.7931 5.16728 84.8451C5.13019 84.8525 5.14132 84.9046 5.17841 84.9008C5.48259 84.8674 5.78677 84.8785 6.09095 84.8711C6.13918 84.8711 6.1503 84.7968 6.09837 84.7931V84.7894Z" fill="#262626" />
        <path d="M26.8643 121.306H11.199L13.4321 104.915L14.0034 100.712H24.0599L24.6311 104.915L26.8643 121.306Z" fill="#262626" />
        <path d="M24.6312 104.915H13.4321L14.0034 100.712H24.0599L24.6312 104.915Z" fill="#262626" />
        <path d="M25.4955 99.2554H12.5641V102.815H25.4955V99.2554Z" fill="#262626" />
        <path d="M114.765 149C173.51 149 221.132 143.773 221.132 137.325C221.132 130.877 173.51 125.649 114.765 125.649C56.0203 125.649 8.39832 130.877 8.39832 137.325C8.39832 143.773 56.0203 149 114.765 149Z" fill="#262626" />
        <path d="M90.0039 135.389C89.1396 135.682 64.3563 135.768 63.4067 135.173C63.0469 134.95 62.8243 131.335 62.6759 126.957C62.6425 126.013 62.6166 125.036 62.5943 124.044L60.5837 110.801L77.1504 110.693L78.7158 123.936L78.6528 126.849C78.6528 126.849 88.1566 131.067 89.1247 131.832C90.0892 132.598 90.8645 135.088 90.0002 135.381L90.0039 135.389Z" fill="#EB9481" />
        <path d="M90.0039 135.389C89.1396 135.682 64.3563 135.768 63.4067 135.173C62.969 134.898 62.7316 129.618 62.598 124.048L78.7196 123.944L78.6565 126.857C78.6565 126.857 88.1603 131.074 89.1285 131.84C90.0929 132.605 90.8682 135.095 90.0039 135.389Z" fill="white" />
        <path d="M90.0039 135.389C89.1396 135.682 64.3563 135.768 63.4067 135.173C63.0468 134.95 62.8243 131.335 62.6759 126.957L78.6565 126.853C78.6565 126.853 88.1603 131.071 89.1284 131.836C90.0929 132.602 90.8682 135.091 90.0039 135.385V135.389Z" fill="#A6A6A6" />
        <path d="M89.644 134.396C85.4894 134.307 68.7224 134.348 64.6085 134.579C64.5751 134.579 64.5751 134.605 64.6085 134.605C68.7261 134.783 85.4894 134.605 89.644 134.463C89.7331 134.463 89.7331 134.396 89.644 134.396Z" fill="#263238" />
        <path d="M87.5333 130.952C86.5466 130.915 85.5858 131.387 85.0516 131.977C84.536 132.542 84.0983 133.341 84.1317 134.203C84.1317 134.229 84.1799 134.229 84.1836 134.203C84.4136 132.572 85.89 131.138 87.537 131.004C87.5704 131.004 87.5704 130.952 87.537 130.952H87.5333Z" fill="#263238" />
        <path d="M80.2702 127.266C78.6083 127.247 76.6831 127.842 75.6518 129.209C75.611 129.261 75.6889 129.317 75.7371 129.291C77.2395 128.511 78.6639 127.927 80.2998 127.47C80.4148 127.437 80.3889 127.266 80.2702 127.266Z" fill="#263238" />
        <path d="M81.5573 127.764C79.8954 127.745 77.9702 128.34 76.9389 129.707C76.8981 129.759 76.976 129.815 77.0242 129.789C78.5229 129.008 79.951 128.425 81.5869 127.968C81.7019 127.935 81.676 127.764 81.5573 127.764Z" fill="#263238" />
        <path d="M82.8408 128.262C81.179 128.243 79.2537 128.837 78.2225 130.205C78.1817 130.257 78.2596 130.313 78.3078 130.287C79.8065 129.506 81.2346 128.923 82.8705 128.466C82.9855 128.432 82.9595 128.262 82.8408 128.262Z" fill="#263238" />
        <path d="M84.1279 128.756C82.4661 128.737 80.5408 129.332 79.5096 130.699C79.4688 130.751 79.5467 130.807 79.5949 130.781C81.0936 130.001 82.5217 129.417 84.1576 128.96C84.2726 128.927 84.2466 128.756 84.1279 128.756Z" fill="#263238" />
        <path d="M65.6546 130.625C63.7887 130.636 63.8036 133.542 65.6732 133.531C67.5391 133.52 67.5242 130.614 65.6546 130.625Z" fill="#263238" />
        <path d="M82.0506 122.814C81.2049 122.119 80.1476 122.807 79.632 123.531C78.7974 124.709 78.2224 126.359 78.1371 127.797C78.1371 127.834 78.1631 127.845 78.189 127.845C78.2261 127.964 78.3671 128.065 78.4895 127.979C79.6691 127.154 81.0342 126.418 81.9282 125.255C82.4513 124.575 82.8519 123.472 82.0506 122.814ZM80.0215 126.363C79.4503 126.783 78.8642 127.173 78.3077 127.611C78.6008 126.779 78.8567 125.939 79.2351 125.14C79.4169 124.757 79.6061 124.367 79.8398 124.014C80.1996 123.464 81.8281 122.063 81.9987 123.877C82.0951 124.876 80.7041 125.861 80.0215 126.363Z" fill="#263238" />
        <path d="M74.0084 128.432C75.4588 128.652 76.95 128.232 78.3708 128.005C78.5192 127.983 78.5526 127.812 78.4932 127.7C78.5155 127.682 78.5229 127.656 78.4969 127.63C77.4397 126.656 75.8854 125.865 74.4684 125.593C73.5929 125.426 72.354 125.668 72.2278 126.757C72.1091 127.786 73.1626 128.302 74.0084 128.429V128.432ZM72.9994 127.489C71.868 126.065 74.0084 125.943 74.6501 126.088C75.0619 126.184 75.4699 126.329 75.8632 126.482C76.6904 126.797 77.4545 127.225 78.2447 127.619C77.5398 127.686 76.8462 127.816 76.1451 127.908C75.3067 128.02 73.6226 128.276 72.9994 127.489Z" fill="#263238" />
        <path d="M36.2419 49.596C36.2419 49.596 55.4832 82.3851 55.9988 84.6852C56.7889 88.2227 60.9881 123.156 60.9881 123.156L81.0084 122.795C81.0084 122.795 77.5585 91.9944 74.2904 81.8463C71.9349 74.5371 54.9564 50.2946 54.9564 50.2946L36.2457 49.5923L36.2419 49.596Z" fill="#37474F" />
        <path d="M79.1869 119.722C76.3121 119.7 66.122 119.585 62.2975 119.945C62.2678 119.945 62.2678 119.997 62.2975 120.001C63.6144 120.191 76.3158 119.897 79.1869 119.804C79.2426 119.804 79.2389 119.726 79.1869 119.726V119.722Z" fill="#263238" />
        <path d="M39.5249 52.6058C40.7861 54.5864 42.1178 56.5261 43.405 58.4918C44.6959 60.4575 45.9794 62.4232 47.2481 64.4038C49.7817 68.3575 52.2522 72.3521 54.6745 76.3727C55.8096 78.2566 56.8928 80.1629 57.6755 82.2178C58.4471 84.2541 58.9738 86.3759 59.3633 88.5125C59.7565 90.664 60.0125 92.8378 60.2573 95.0116C60.517 97.3266 60.7729 99.6379 61.0326 101.953C61.5594 106.687 62.0861 111.421 62.6166 116.155C62.6796 116.735 62.7464 117.318 62.8021 117.898C62.8095 117.983 62.6574 117.998 62.6463 117.913C62.0564 113.208 61.5668 108.493 61.0326 103.781C60.7655 101.436 60.4984 99.0917 60.2351 96.7469C59.9865 94.5657 59.7528 92.3845 59.4153 90.2144C59.0851 88.0852 58.6511 85.9634 58.0056 83.9011C57.3305 81.7459 56.3698 79.7244 55.2384 77.7661C52.9199 73.7567 50.4642 69.8178 48.0085 65.8864C45.5306 61.9216 43.0341 57.9716 40.4411 54.0773C40.1221 53.598 39.7994 53.1186 39.4655 52.6467C39.4395 52.6095 39.5026 52.5761 39.5286 52.6132L39.5249 52.6058Z" fill="#263238" />
        <path d="M42.5482 137.325C41.6356 137.269 18.0654 127.704 17.4088 126.798C17.3865 126.768 17.3754 126.708 17.3717 126.623C17.3754 125.813 19.0076 122.97 20.3727 119.239C20.458 119.002 21.0664 117.894 21.9121 116.4C24.2343 112.302 28.3407 105.282 28.3407 105.282L43.7463 111.414L36.3718 122.472L35.2256 125.148C35.2256 125.148 42.4554 132.631 43.0675 133.705C43.6759 134.776 43.4607 137.377 42.5519 137.325H42.5482Z" fill="#EB9481" />
        <path d="M42.5482 137.325C41.6356 137.269 18.0654 127.704 17.4088 126.797C17.3865 126.768 17.3754 126.708 17.3717 126.623C17.3754 125.813 19.0076 122.97 20.3727 119.239C20.458 119.002 21.0664 117.894 21.9121 116.4L36.3718 122.468L35.2256 125.144C35.2256 125.144 42.4554 132.628 43.0675 133.702C43.6759 134.772 43.4607 137.373 42.5519 137.321L42.5482 137.325Z" fill="white" />
        <path d="M42.5481 137.321C41.6356 137.269 18.069 127.7 17.4125 126.794C17.1639 126.452 18.2174 123.227 20.3764 119.239L35.2255 125.148C35.2255 125.148 42.4554 132.628 43.0675 133.702C43.6758 134.772 43.4607 137.373 42.5481 137.321Z" fill="#A6A6A6" />
        <path d="M42.5852 136.269C38.7681 134.627 23.2104 128.362 19.3117 127.032C19.2783 127.021 19.2709 127.043 19.3006 127.058C23.0509 128.767 38.6568 134.906 42.5592 136.336C42.6408 136.366 42.6668 136.307 42.5852 136.273V136.269Z" fill="#263238" />
        <path d="M41.9175 132.282C41.0161 131.877 39.9515 131.955 39.2318 132.301C38.5418 132.632 37.837 133.204 37.544 134.018C37.5366 134.04 37.5774 134.058 37.5922 134.036C38.4157 132.613 40.3224 131.836 41.899 132.331C41.9323 132.342 41.9472 132.297 41.9175 132.282Z" fill="#263238" />
        <path d="M36.5646 126.132C35.0289 125.493 33.022 125.319 31.5568 126.199C31.5011 126.233 31.5494 126.314 31.605 126.307C33.2891 126.147 34.8286 126.144 36.5164 126.333C36.6351 126.348 36.6759 126.177 36.5646 126.132Z" fill="#263238" />
        <path d="M37.5699 127.076C36.0341 126.433 34.0273 126.262 32.562 127.143C32.5064 127.177 32.5546 127.258 32.6103 127.251C34.2944 127.091 35.8338 127.087 37.5217 127.277C37.6404 127.292 37.6812 127.121 37.5699 127.076Z" fill="#263238" />
        <path d="M38.5789 128.02C37.0432 127.381 35.0363 127.206 33.5711 128.087C33.5154 128.12 33.5636 128.202 33.6193 128.195C35.3034 128.035 36.8429 128.031 38.5307 128.221C38.6494 128.236 38.6902 128.065 38.5789 128.02Z" fill="#263238" />
        <path d="M39.5842 128.964C38.0484 128.321 36.0416 128.15 34.5763 129.031C34.5207 129.064 34.5689 129.146 34.6245 129.139C36.3087 128.979 37.8481 128.975 39.5359 129.165C39.6546 129.179 39.6954 129.009 39.5842 128.964Z" fill="#263238" />
        <path d="M21.76 123.754C20.0277 123.063 18.9519 125.761 20.688 126.452C22.4203 127.143 23.4961 124.445 21.76 123.754Z" fill="#263238" />
        <path d="M39.8847 122.677C39.3616 121.714 38.1264 121.952 37.3733 122.435C36.1566 123.212 35.0104 124.527 34.3909 125.828C34.3761 125.861 34.3946 125.883 34.4206 125.891C34.4094 126.013 34.5022 126.162 34.6469 126.129C36.049 125.809 37.5922 125.638 38.8534 124.899C39.5916 124.464 40.3781 123.591 39.8847 122.68V122.677ZM36.676 125.203C35.9897 125.378 35.2997 125.519 34.6172 125.716C35.1996 125.055 35.7523 124.375 36.4052 123.773C36.7168 123.487 37.0395 123.197 37.3845 122.955C37.9224 122.58 39.9552 121.896 39.4358 123.639C39.1502 124.601 37.4921 124.992 36.6722 125.2L36.676 125.203Z" fill="#263238" />
        <path d="M30.3252 124.862C31.5864 125.608 33.1259 125.779 34.5281 126.106C34.6727 126.14 34.7692 125.995 34.7581 125.872C34.784 125.865 34.8026 125.843 34.7877 125.809C34.172 124.509 33.0294 123.189 31.8127 122.409C31.0634 121.926 29.8244 121.684 29.3014 122.643C28.8043 123.554 29.587 124.427 30.3252 124.862ZM29.7465 123.606C29.2309 121.859 31.2637 122.55 31.8016 122.929C32.1466 123.171 32.4693 123.461 32.7809 123.751C33.4301 124.353 33.9791 125.036 34.5615 125.698C33.8826 125.497 33.1926 125.356 32.5064 125.177C31.6903 124.966 30.0321 124.568 29.7502 123.606H29.7465Z" fill="#263238" />
        <path d="M37.5625 121.852L20.317 114.877C20.317 114.877 41.6987 83.3884 41.8545 81.0437C41.9954 78.9665 32.6029 60.8998 32.232 55.6344C31.8313 49.9528 34.5653 45.5532 37.5663 42.8257L55.3571 50.3987C55.3571 50.3987 53.4801 53.4086 50.5236 55.0807C50.5236 55.0807 59.6453 75.964 59.1297 82.3851C58.6103 88.8396 37.5625 121.852 37.5625 121.852Z" fill="#37474F" />
        <path d="M38.3267 118.567C35.8339 117.493 36.5647 117.764 34.057 116.72C32.8292 116.207 24.7721 112.852 23.5443 112.577C23.5183 112.569 23.4998 112.614 23.522 112.629C24.5941 113.286 32.7105 116.501 33.9495 116.991C36.4756 117.987 35.7597 117.675 38.297 118.645C38.3452 118.663 38.3749 118.593 38.3267 118.57V118.567Z" fill="#263238" />
        <path d="M53.4503 54.7017C51.5956 55.0993 49.7779 55.757 48.0307 56.463C47.964 56.489 47.9936 56.5931 48.0678 56.5708C49.8855 56.0394 51.7365 55.5415 53.5208 54.9135C53.6618 54.8652 53.5913 54.672 53.4503 54.7017Z" fill="#263238" />
        <path d="M51.3396 53.9696C51.1653 54.2743 51.0169 54.5976 50.8834 54.9246C50.7795 55.1773 50.6497 55.4485 50.6497 55.7235C50.6497 55.7755 50.7201 55.7904 50.7535 55.7569C50.9613 55.5526 51.0688 55.2553 51.1987 55.0026C51.3508 54.7091 51.5066 54.4155 51.6512 54.1182C51.7477 53.9213 51.4435 53.7801 51.3359 53.9659L51.3396 53.9696Z" fill="#263238" />
        <path d="M51.5547 52.2938C51.6623 52.0188 51.7736 51.7475 51.8886 51.4726C52.0073 51.1901 52.1445 50.9152 52.2447 50.6253C52.2595 50.5807 52.3337 50.6105 52.3152 50.6551C52.2076 50.93 52.1371 51.2236 52.0481 51.506C51.9591 51.7884 51.87 52.0708 51.7736 52.3532C51.5992 52.8772 51.3878 53.3974 51.1615 53.9027C51.4509 53.899 51.7513 53.8879 52.0147 53.7541C52.3003 53.6092 52.4747 53.3045 52.6157 53.0295C52.7715 52.7211 52.9161 52.409 53.0793 52.1043C53.2574 51.7661 53.454 51.4354 53.5727 51.0712C53.5875 51.0266 53.658 51.0564 53.6432 51.101C53.3761 51.8516 53.1127 52.6059 52.6936 53.2971C52.5303 53.5683 52.3968 53.8544 52.0963 53.9585C51.7736 54.0737 51.4175 54.0514 51.0799 54.0254C51.0391 54.0365 50.9835 54.0105 50.9983 53.9585C51.1578 53.3974 51.3359 52.8289 51.5547 52.2863V52.2938Z" fill="#263238" />
        <path d="M50.798 55.7644C51.4287 57.1059 51.9851 58.4436 52.5786 59.7813C53.1573 61.0893 53.6952 62.4122 54.2219 63.7387C55.2828 66.4253 56.2325 69.1602 57.0671 71.9248C57.4789 73.2923 57.861 74.6709 58.2022 76.0569C58.5472 77.4467 58.8811 78.8513 59.0257 80.2819C59.1704 81.7162 59.0666 83.132 58.6696 84.518C58.2653 85.9226 57.7311 87.1526 57.1005 88.468C56.7147 89.2707 56.5812 89.1629 56.7667 88.7579C57.2971 87.6059 58.6882 84.1167 58.588 81.1849C58.5398 79.7691 58.2505 78.3756 57.9314 77.0008C57.605 75.5962 57.2378 74.2027 56.8371 72.8204C56.047 70.0929 55.1085 67.4212 54.144 64.7532C53.6024 63.2594 53.0238 61.7805 52.4747 60.2867C51.922 58.7854 51.3508 57.4329 50.7387 55.7904C50.7239 55.7458 50.7869 55.7235 50.8055 55.7644H50.798Z" fill="#263238" />
        <path d="M37.7072 51.3276C38.0151 51.2124 38.4454 51.402 38.7607 51.454C39.165 51.5209 39.5731 51.5617 39.9811 51.5766C40.3855 51.5915 40.7898 51.5766 41.1941 51.5357C41.5948 51.4986 42.0065 51.4465 42.3552 51.2273C42.945 50.8594 43.4347 50.2129 43.8353 49.6518C44.273 49.0386 44.7293 48.3326 44.989 47.6229C45.0038 47.5783 45.0669 47.608 45.0557 47.6526C44.9259 48.0651 44.8295 48.485 44.6625 48.89C44.5067 49.269 44.3064 49.6332 44.095 49.9862C43.6981 50.6402 43.2418 51.3425 42.5592 51.7327C41.8433 52.1414 40.9233 52.1154 40.1258 52.0708C39.6658 52.0448 39.2058 51.9816 38.7533 51.8887C38.4268 51.8181 37.8778 51.7661 37.6701 51.48C37.633 51.428 37.6516 51.3499 37.7146 51.3276H37.7072Z" fill="#263238" />
        <path d="M25.2395 111.585C28.0735 107.289 30.8557 102.956 33.5859 98.5939C36.3235 94.2202 39.0129 89.8132 41.6652 85.3839C42.2995 84.3248 43.0303 83.2658 43.4383 82.0916C43.8835 80.817 43.6312 79.5796 43.2269 78.3311C42.4887 76.0421 41.5725 73.8163 40.5783 71.6276C39.5842 69.4315 38.5121 67.2726 37.4586 65.1062C36.4125 62.9584 35.3034 60.7995 34.5095 58.5402C33.7528 56.3813 33.4635 54.1443 34.0458 51.9073C34.6208 49.6852 35.8672 47.6935 37.1693 45.8319C37.5142 45.3414 37.8629 44.8546 38.2079 44.3641C38.2339 44.3269 38.2895 44.3715 38.2636 44.4087C36.828 46.3484 35.4295 48.4033 34.5874 50.6811C34.172 51.807 33.9012 52.9924 33.8752 54.1926C33.8492 55.4114 34.0755 56.6228 34.4353 57.7859C35.1327 60.0452 36.2345 62.1855 37.262 64.3073C38.3118 66.4774 39.3875 68.6326 40.4002 70.8213C41.4167 73.0211 42.37 75.258 43.1527 77.5545C43.5014 78.5763 43.8909 79.6465 43.8649 80.7427C43.839 81.9429 43.2677 83.0243 42.6742 84.035C40.0961 88.442 37.4178 92.7896 34.7099 97.1149C31.9908 101.455 29.2161 105.766 26.3968 110.043C26.0407 110.581 25.6846 111.12 25.3285 111.659C25.284 111.726 25.1875 111.648 25.232 111.581L25.2395 111.585Z" fill="#263238" />
        <path d="M56.344 30.37C57.9502 33.9001 66.0592 38.7642 69.8577 39.2882C72.5916 39.6635 80.8898 39.7935 83.735 39.3588C87.0699 38.8497 84.5214 29.8349 82.5517 30.0653C77.1358 30.7007 72.536 31.4068 71.093 31.4068C69.3273 31.4068 59.7753 28.3337 58.6216 27.9027C55.8061 26.8436 55.4314 28.3709 56.344 30.37Z" fill="#EB9481" />
        <path d="M56.3959 31.3064C58.4287 34.32 65.1874 39.2472 65.1874 39.2472L66.1259 37.1663L69.2308 30.2882C69.2308 30.2882 63.0248 28.3262 58.9406 27.453C58.3879 27.3341 57.9019 27.2635 57.479 27.2449C54.8713 27.1037 54.645 28.7201 56.3996 31.3101L56.3959 31.3064Z" fill="url(#paint0_linear_379_2620)" />
        <path d="M56.3959 31.3064C58.4287 34.32 65.1874 39.2473 65.1874 39.2473L66.1259 37.1664C63.6072 34.2345 59.7159 29.794 57.479 27.2412C54.8713 27.1 54.645 28.7164 56.3996 31.3064H56.3959Z" fill="#263238" />
        <path d="M63.967 37.8538C64.4827 36.5904 66.7751 31.4885 67.3464 30.4183C67.3872 30.344 67.4428 30.3514 67.4095 30.4332C65.9553 34.2569 64.5828 36.6573 64.0153 37.8724C64.0004 37.9021 63.9522 37.8873 63.9633 37.8538H63.967Z" fill="#263238" />
        <path d="M81.5612 30.1656C84.859 29.7309 88.9357 28.9914 92.3856 30.4592C93.0644 30.7453 93.3092 31.2544 93.3092 31.2544C94.8672 31.8861 95.0379 32.8113 95.0379 32.8113C96.3102 33.8444 96.3547 34.8179 96.3547 34.8179C96.3547 34.8179 98.1761 35.5797 97.9016 36.7576C97.5974 38.0805 95.0972 36.7576 93.2758 36.8654C89.1064 37.1218 83.249 38.6564 82.0546 38.8534L81.5575 30.1693L81.5612 30.1656Z" fill="#EB9481" />
        <path d="M88.7168 34.0599C89.9447 34.0079 91.1502 33.9261 92.3818 34.0413C93.7321 34.1677 95.0081 34.517 96.3139 34.8403C96.3547 34.8514 96.3732 34.7957 96.3324 34.7771C94.0548 33.8184 91.1354 33.5657 88.7131 34.0042C88.6537 34.0153 88.6537 34.0636 88.7131 34.0599H88.7168Z" fill="#263238" />
        <path d="M88.1976 31.5591C89.3957 31.574 91.5028 31.6186 95.0268 32.8486C95.0379 32.8523 95.049 32.8337 95.0379 32.83C92.8567 31.7264 90.6199 31.3325 88.2013 31.5034C88.1271 31.5071 88.1197 31.5591 88.2013 31.5591H88.1976Z" fill="#263238" />
        <path d="M87.9416 30.2139C89.9781 30.318 91.2504 30.645 93.2981 31.2618C93.3092 31.2618 93.3204 31.2469 93.3055 31.2395C91.0501 30.2919 90.1265 30.2065 87.9341 30.1433C87.86 30.1433 87.86 30.2102 87.9416 30.2139Z" fill="#263238" />
        <path d="M88.2717 37.4934C87.7413 37.7424 87.8674 38.0211 88.8838 38.2589C89.9076 38.5004 94.1068 38.5636 93.9584 40.0314C93.8137 41.5066 87.8711 41.3171 86.2129 40.7968C84.5548 40.2803 82.0546 38.8497 82.0546 38.8497C83.2194 38.6528 85.9941 37.9059 88.2717 37.4934Z" fill="#EB9481" />
        <path d="M60.7545 35.9996C60.7285 36.635 60.6654 37.2816 60.5764 37.9207C60.5838 37.8984 60.595 37.8761 60.6024 37.8538C60.7248 37.5194 60.8472 37.1775 60.9956 36.8542C61.003 36.8356 61.0327 36.8431 61.029 36.8617C60.9622 37.2109 60.8398 37.564 60.7062 37.8947C60.6432 38.0545 60.5764 38.2105 60.5096 38.3666C60.4651 38.6527 60.4132 38.9351 60.3538 39.2138C60.2425 39.7452 59.7418 42.0045 59.6305 42.3872C59.6082 42.4689 59.5118 42.4504 59.5155 42.3612C59.5415 41.945 59.9643 39.5891 60.0497 39.0577C60.2129 38.0359 60.4392 36.9917 60.7099 35.9921C60.7174 35.9698 60.7508 35.981 60.7508 36.0033L60.7545 35.9996Z" fill="url(#paint1_linear_379_2620)" />
        <path d="M56.8892 52.892C55.8987 53.4048 54.6227 54.1963 48.8729 53.0592C48.5131 52.9849 48.1533 52.8994 47.7897 52.7917C42.3998 51.1678 35.9304 44.8174 35.5669 44.3714C35.5669 44.3714 44.1285 30.4628 55.0826 26.0892C56.4366 25.5504 58.6772 26.5909 59.9199 27.8877C60.3613 28.3522 61.3258 31.7634 60.9548 34.528C60.5839 37.2889 57.8833 52.3866 56.8855 52.892H56.8892Z" fill="url(#paint2_linear_379_2620)" />
        <path d="M57.9094 31.5889C56.5146 31.3287 55.1977 28.4341 55.0308 26.4869C55.0196 26.3532 55.6836 25.2941 56.4293 23.9676C56.8781 23.1612 57.3603 22.2508 57.7387 21.3924C57.8166 21.2141 58.2729 21.8049 58.2729 21.8049L59.267 22.5667L62.55 25.0898C62.55 25.0898 61.222 26.6616 60.5691 28.1665C60.5171 28.2929 60.4763 28.4155 60.4504 28.5381C60.4467 28.5567 60.443 28.5753 60.4318 28.6013C60.2464 29.3222 59.2485 31.8453 57.9019 31.5926L57.9094 31.5889Z" fill="#EB9481" />
        <path d="M60.4578 28.5381C60.4578 28.5381 60.4504 28.5753 60.4393 28.6013C60.2612 28.5493 60.072 28.4787 59.8717 28.3858C56.2772 26.6988 57.8612 21.8718 57.8612 21.8718L59.2671 22.5704L62.55 25.0935C62.55 25.0935 61.222 26.6653 60.5691 28.1703C60.5172 28.2966 60.4764 28.4192 60.4504 28.5419L60.4578 28.5381Z" fill="#263238" />
        <path d="M66.3262 14.8079C66.3262 14.8079 67.9695 15.852 67.9806 17.71C67.9917 19.5679 67.2535 21.385 67.0829 21.4482C66.9123 21.5113 66.3299 14.8079 66.3299 14.8079H66.3262Z" fill="#263238" />
        <path d="M58.1727 15.7702C57.186 17.7248 57.2342 23.8709 58.4694 25.4539C60.2574 27.7503 63.6999 28.5418 65.7809 26.2714C67.7952 24.0715 67.5911 16.0601 66.163 14.5663C64.0634 12.3665 59.6342 12.8867 58.1764 15.7702H58.1727Z" fill="#EB9481" />
        <path d="M63.4105 20.6975C63.4105 20.6975 63.3771 20.7198 63.3808 20.7347C63.4031 21.1546 63.3585 21.6376 62.9839 21.7863C62.9728 21.79 62.9728 21.8123 62.9876 21.8086C63.4476 21.7342 63.4995 21.0914 63.4105 20.6975Z" fill="#263238" />
        <path d="M63.0284 20.2665C62.3533 20.2293 62.3347 21.5856 62.9579 21.6154C63.5737 21.6488 63.5923 20.2962 63.0284 20.2665Z" fill="#263238" />
        <path d="M65.6993 20.6827C65.6993 20.6827 65.7327 20.7013 65.7327 20.7199C65.7141 21.1398 65.7586 21.6229 66.137 21.7678C66.1481 21.7715 66.1481 21.7938 66.1333 21.7901C65.6733 21.7195 65.614 21.0766 65.703 20.6827H65.6993Z" fill="#263238" />
        <path d="M66.0814 20.248C66.7565 20.2071 66.7862 21.5597 66.163 21.5968C65.5472 21.634 65.5175 20.2814 66.0814 20.248Z" fill="#263238" />
        <path d="M62.6056 19.2372C62.7911 19.1926 62.9543 19.122 63.1323 19.0626C63.3326 18.992 63.4996 18.9585 63.6331 18.7801C63.7036 18.6835 63.6776 18.5126 63.5923 18.4346C63.392 18.2525 63.1249 18.2748 62.8801 18.3491C62.613 18.4309 62.4275 18.5609 62.2717 18.7913C62.1196 19.0142 62.3608 19.2967 62.6019 19.2409L62.6056 19.2372Z" fill="#263238" />
        <path d="M66.5488 19.8392C66.3633 19.8057 66.1964 19.7426 66.0146 19.6906C65.8106 19.6311 65.64 19.6051 65.499 19.4342C65.4211 19.3413 65.4396 19.1703 65.5213 19.0849C65.7104 18.8916 65.9775 18.9028 66.2298 18.9622C66.5006 19.0291 66.6898 19.148 66.8604 19.371C67.0236 19.5865 66.801 19.8801 66.5525 19.8355L66.5488 19.8392Z" fill="#263238" />
        <path d="M62.4572 24.6661C62.5574 24.7664 62.6538 24.9039 62.8059 24.9188C62.9506 24.9336 63.1212 24.8705 63.2585 24.8222C63.2733 24.8184 63.2844 24.8333 63.277 24.8445C63.1657 25.0042 62.9357 25.1008 62.7429 25.0637C62.5537 25.0265 62.4646 24.863 62.4201 24.6884C62.4127 24.6624 62.4461 24.6549 62.4609 24.6698L62.4572 24.6661Z" fill="#263238" />
        <path d="M64.887 22.8751C64.887 22.8751 64.9055 23.5031 64.8721 23.7929C64.8721 23.8189 64.0486 23.6591 63.1658 23.8524C63.0322 23.8821 63.0471 23.7743 63.1992 23.7037C63.507 23.5625 63.8928 23.4288 64.6496 23.5105C64.6792 23.4325 64.6051 22.5444 64.6496 22.5481C64.8091 22.5518 65.1133 22.693 65.295 22.771C65.295 21.463 65.0873 20.1662 65.1058 18.8656C65.1058 18.821 65.1763 18.8136 65.1837 18.8545C65.4991 20.1996 65.5287 21.7046 65.5658 23.0535C65.5732 23.2207 64.9983 22.9383 64.887 22.8751Z" fill="#263238" />
        <path d="M64.0523 23.5699C64.0523 23.5699 63.7592 24.3056 63.2733 24.5026C62.7131 24.7292 62.5202 24.2202 63.0915 23.7705C63.392 23.5364 64.0523 23.5699 64.0523 23.5699Z" fill="#263238" />
        <path d="M63.4698 24.3874C63.4068 24.4357 63.3437 24.4766 63.2732 24.5026C62.7576 24.7107 62.5536 24.2983 62.969 23.8821C63.251 23.9044 63.5589 24.0084 63.4698 24.3874Z" fill="#FF99BA" />
        <path d="M57.8908 21.0319C58.5696 21.24 59.3486 19.1963 59.5712 18.4122C59.7641 17.7211 59.9904 15.525 60.0238 15.3726C60.0571 15.224 62.9802 17.316 64.6903 16.8144C66.4004 16.3128 67.5429 14.7781 67.5652 14.0609C67.5875 13.3438 65.4656 11.523 63.6294 11.4301C61.7932 11.3372 59.3078 13.6745 59.3078 13.6745C59.3078 13.6745 60.1536 12.6637 60.1239 12.582C60.0942 12.5002 58.9072 12.9759 58.6883 13.8194C58.6883 13.8194 58.922 12.7566 58.833 12.7381C58.744 12.7195 57.8203 13.6299 58.028 14.3173C58.028 14.3173 56.9597 15.2426 56.8633 16.257C56.7631 17.2715 57.0932 20.783 57.8908 21.0282V21.0319Z" fill="#263238" />
        <path d="M65.9034 16.4576C65.3841 16.6954 64.861 16.9593 64.2897 17.0373C63.6703 17.119 63.0248 16.963 62.4461 16.74C61.8563 16.5134 61.3184 16.1826 60.8288 15.7813C60.4022 15.4283 60.0201 15.0047 59.5638 14.6889C59.4488 14.6108 59.319 14.7743 59.3858 14.8821C60.0795 15.9225 61.3073 16.7958 62.4869 17.1711C63.7296 17.565 64.9018 17.29 65.9071 16.4725C65.9145 16.4688 65.9071 16.4539 65.8997 16.4576H65.9034Z" fill="#263238" />
        <path d="M58.5993 20.8499C58.5993 20.8499 57.5458 18.7095 56.5961 19.0551C55.6465 19.4007 56.2326 22.3622 57.2008 22.901C58.169 23.4436 58.6883 22.5555 58.6883 22.5555L58.5993 20.8499Z" fill="#EB9481" />
        <path d="M56.7854 19.8986C56.7854 19.8986 56.7631 19.9172 56.7742 19.9246C57.4494 20.3297 57.7276 21.058 57.8871 21.79C57.7127 21.489 57.4457 21.2995 57.0487 21.5002C57.0265 21.5113 57.0413 21.5448 57.0636 21.541C57.3678 21.5225 57.5755 21.6079 57.735 21.8755C57.85 22.065 57.9131 22.2916 57.9872 22.4997C58.0095 22.5703 58.1319 22.5518 58.1208 22.4737C58.1208 22.4663 58.1208 22.4626 58.1208 22.4551C58.2803 21.5373 57.7647 20.1401 56.7928 19.8949L56.7854 19.8986Z" fill="#263238" />
        <path d="M51.644 30.4034C50.9169 34.6136 55.4574 48.7971 58.3767 51.4466C62.7206 55.3854 70.7517 57.9754 73.6637 58.8189C74.9323 59.1868 78.4415 50.2463 77.158 49.6555C74.4093 48.3958 65.87 45.7278 65.065 45.0404C64.26 44.3566 59.3524 37.2816 57.1712 33.2052C54.2444 27.7317 52.0075 28.2742 51.6403 30.4034H51.644Z" fill="#EB9481" />
        <path d="M74.9954 48.8714C74.9954 48.8714 78.7828 49.8375 81.2311 50.5881C82.5962 51.0043 84.8738 51.9333 85.7381 52.6988C87.029 53.847 91.6956 59.4654 90.3602 60.6285C89.1509 61.6801 85.753 56.3403 85.753 56.3403C85.753 56.3403 89.6554 61.4237 87.9675 62.4233C86.2797 63.4228 83.2416 56.8792 83.2416 56.8792C83.2416 56.8792 87.1366 62.1929 85.2299 62.9472C83.7906 63.5157 80.7043 57.745 80.7043 57.745C80.7043 57.745 84.0206 62.3341 82.5665 62.9844C80.9603 63.7015 77.7775 58.555 77.7775 58.555C73.8009 59.5843 71.4899 58.4435 69.9393 57.4365C69.5646 57.1913 74.9991 48.8677 74.9991 48.8677L74.9954 48.8714Z" fill="#EB9481" />
        <path d="M84.5139 54.4972C84.9924 55.0769 85.4821 55.664 85.9124 56.2846C86.339 56.9051 86.7433 57.5443 87.1106 58.202C88.012 59.8184 88.3236 60.9703 88.2976 60.8997C87.6596 59.2647 86.1646 57.0166 85.7381 56.3961C85.3115 55.7755 84.9182 55.1327 84.4917 54.5121C84.4805 54.4972 84.5028 54.4824 84.5176 54.4972H84.5139Z" fill="#263238" />
        <path d="M82.2475 55.9985C83.071 56.6525 84.7959 59.0864 85.4636 60.714C85.4933 60.7809 85.4562 60.7772 85.4154 60.714C84.8924 59.9076 82.7891 56.7863 82.2289 56.0097C82.2215 55.9985 82.2363 55.9874 82.2475 55.9985Z" fill="#263238" />
        <path d="M80.07 56.9386C81.0233 57.8824 81.8208 59.1718 82.4515 60.6619C82.4811 60.7288 82.4255 60.7176 82.3884 60.6545C81.6205 59.4022 81.027 58.4138 80.0329 56.9683C80.018 56.946 80.0514 56.9125 80.0737 56.9348L80.07 56.9386Z" fill="#263238" />
        <path d="M55.7726 30.7156C52.3858 25.9184 50.3604 27.6946 51.0393 32.9042C51.6736 37.7758 53.38 43.58 53.38 43.58L61.9267 39.4331C61.9267 39.4331 57.939 33.7886 55.7726 30.7156Z" fill="url(#paint3_linear_379_2620)" />
        <path d="M57.8425 32.5996C58.0651 33.1496 58.1689 33.7292 58.1393 34.3238C57.913 33.7738 57.8165 33.1942 57.8425 32.5996Z" fill="white" />
        <path d="M52.7864 35.5203C53.3354 35.2973 53.9141 35.1933 54.5076 35.223C53.9586 35.4497 53.3799 35.5463 52.7864 35.5203Z" fill="white" />
        <path d="M53.0053 28.0551C52.7753 28.605 52.4377 29.0844 52 29.4857C52.23 28.9357 52.5676 28.4601 53.0053 28.0551Z" fill="white" />
        <path d="M60.2166 37.761C59.9866 38.3109 59.649 38.7903 59.2113 39.1916C59.4413 38.6417 59.7789 38.166 60.2166 37.761Z" fill="white" />
        <path d="M54.3037 40.6705C53.7546 40.4401 53.2761 40.1019 52.8755 39.6635C53.4245 39.8938 53.8993 40.232 54.3037 40.6705Z" fill="white" />
        <path d="M56.9077 32.1573C57.364 32.7853 57.8351 33.3985 58.3025 34.0153C58.1838 33.7032 58.0799 33.391 57.9872 33.0677C57.9834 33.0529 58.0057 33.0454 58.0094 33.0603C58.1059 33.3056 58.2097 33.5508 58.3025 33.7961C58.347 33.915 58.3915 34.0339 58.436 34.1528C58.4471 34.1788 58.4545 34.2048 58.4657 34.2308C58.8329 34.7102 59.1965 35.1932 59.5451 35.6837C59.9643 36.2671 60.3687 36.8654 60.7767 37.4599C61.1959 38.0731 61.5965 38.7939 62.0379 39.3922C62.075 39.4442 61.2812 39.7638 61.207 39.8084C61.1328 39.8493 61.0809 39.7787 61.1588 39.7526C61.233 39.7303 61.8636 39.3439 61.871 39.3365C61.463 38.7382 60.9919 38.1845 60.5653 37.5974C60.1424 37.014 59.7158 36.4343 59.304 35.8398C58.4694 34.6358 57.7015 33.3873 56.8743 32.1796C56.8632 32.1648 56.8891 32.1499 56.9003 32.1648L56.9077 32.1573Z" fill="#263238" />
        <path d="M51.2507 36.3488C51.3806 36.6015 51.5215 36.8542 51.6365 37.1143C51.6848 37.2221 51.7293 37.3298 51.7775 37.4376C51.5809 36.5086 51.3917 35.5833 51.2025 34.6544C51.2025 34.6432 51.2174 34.6395 51.2211 34.6507C51.6143 36.1556 52.0075 37.6568 52.371 39.1692C52.7197 40.6109 53.1352 42.0676 53.3355 43.5391C53.4542 43.4982 54.1182 43.2232 54.1367 43.2678C54.1553 43.3124 53.2687 43.7323 53.2539 43.6951C52.7716 42.2608 52.4712 40.6258 52.141 39.1506C52.0483 38.7344 51.963 38.3182 51.8739 37.8983C51.7775 37.6457 51.6625 37.4041 51.5475 37.1626C51.4251 36.9025 51.3286 36.6349 51.2248 36.3674C51.2174 36.3525 51.2433 36.3414 51.2507 36.3562V36.3488Z" fill="#263238" />
        <path d="M53.5173 42.1457C54.7043 41.4731 59.664 38.9017 60.7768 38.4186C60.8547 38.3852 60.8955 38.4261 60.8176 38.4707C57.3084 40.5664 54.7451 41.592 53.547 42.194C53.5173 42.2088 53.4876 42.168 53.5173 42.1494V42.1457Z" fill="#263238" />
        <path d="M189.445 139.837H76.4235L92.7639 46.9949H205.785L189.445 139.837Z" fill="url(#paint4_linear_379_2620)" />
        <path d="M131.447 37.3782H94.4592L92.7639 46.9949H132.348L131.447 37.3782Z" fill="url(#paint5_linear_379_2620)" />
        <g opacity="0.1">
            <path d="M189.445 139.837H76.4235L92.7639 46.9949H205.785L189.445 139.837Z" fill="url(#paint6_linear_379_2620)" />
            <path d="M131.447 37.3782H94.4592L92.7639 46.9949H132.348L131.447 37.3782Z" fill="url(#paint7_linear_379_2620)" />
        </g>
        <path d="M90.4677 41.3022C90.2154 40.2283 90.2117 39.1432 90.4677 38.0693C90.7236 39.1432 90.7199 40.2283 90.4677 41.3022Z" fill="#263238" />
        <path d="M87.181 42.2534C86.4243 41.4508 85.8827 40.5107 85.5674 39.4517C86.3241 40.2543 86.8657 41.1944 87.181 42.2534Z" fill="#263238" />
        <path d="M84.807 44.7244C83.7498 44.4086 82.8113 43.8661 82.01 43.108C83.0672 43.4239 84.002 43.9664 84.807 44.7244Z" fill="#263238" />
        <path d="M83.9872 48.0503C82.9151 48.303 81.8319 48.3067 80.7599 48.0503C81.8319 47.7939 82.9151 47.7976 83.9872 48.0503Z" fill="#263238" />
        <path d="M84.9367 51.3425C84.1355 52.1006 83.197 52.6431 82.1398 52.9589C82.941 52.2009 83.8795 51.6584 84.9367 51.3425Z" fill="#263238" />
        <path d="M87.4037 53.7207C87.0884 54.7797 86.5468 55.7199 85.79 56.5225C86.1053 55.4635 86.6469 54.5271 87.4037 53.7207Z" fill="#263238" />
        <path d="M90.7273 54.5419C90.9796 55.6158 90.9833 56.7008 90.7273 57.7747C90.4714 56.7008 90.4751 55.6158 90.7273 54.5419Z" fill="#263238" />
        <path d="M94.014 53.5906C94.7708 54.3932 95.3124 55.3333 95.6277 56.3924C94.8709 55.5897 94.3293 54.6496 94.014 53.5906Z" fill="#263238" />
        <path d="M96.3844 51.1195C97.4416 51.4354 98.3801 51.9779 99.1814 52.7359C98.1242 52.4201 97.1894 51.8775 96.3844 51.1195Z" fill="#263238" />
        <path d="M97.2041 47.7901C98.2762 47.5375 99.3593 47.5338 100.431 47.7901C99.3593 48.0465 98.2762 48.0428 97.2041 47.7901Z" fill="#263238" />
        <path d="M96.2545 44.4979C97.0558 43.7398 97.9943 43.1973 99.0515 42.8815C98.2502 43.6395 97.3117 44.182 96.2545 44.4979Z" fill="#263238" />
        <path d="M93.7877 42.1233C94.103 41.0643 94.6446 40.1242 95.4014 39.3215C95.086 40.3806 94.5445 41.317 93.7877 42.1233Z" fill="#263238" />
        <path d="M189.278 139.837H76.0117L59.9309 50.3652H173.197L189.278 139.837Z" fill="url(#paint8_linear_379_2620)" />
        <path d="M99.1813 105.059C101.781 105.059 103.889 102.948 103.889 100.344C103.889 97.7397 101.781 95.6285 99.1813 95.6285C96.5814 95.6285 94.4739 97.7397 94.4739 100.344C94.4739 102.948 96.5814 105.059 99.1813 105.059Z" fill="#263238" />
        <path d="M136.395 105.059C138.995 105.059 141.102 102.948 141.102 100.344C141.102 97.7397 138.995 95.6285 136.395 95.6285C133.795 95.6285 131.688 97.7397 131.688 100.344C131.688 102.948 133.795 105.059 136.395 105.059Z" fill="#263238" />
        <path d="M124.551 105.81L124.291 105.316C124.269 105.271 121.835 100.768 116.942 100.768C112.48 100.768 111.393 105.007 111.349 105.19L111.215 105.728L110.135 105.468L110.265 104.926C110.276 104.874 111.59 99.6528 116.942 99.6528C122.295 99.6528 125.166 104.588 125.278 104.799L125.537 105.294L124.551 105.81Z" fill="#263238" />
        <path d="M181.762 99.8015C182.004 99.7495 182.245 99.6938 182.486 99.6306" stroke="#263238" stroke-miterlimit="10" />
        <path d="M183.906 99.2108C189.582 97.2896 194.334 92.8194 194.779 87.5949C195.766 76.0756 181.759 69.714 183.205 56.3999C184.585 43.7027 197.88 42.796 203.211 44.5499C209.098 46.4896 207.08 52.1564 201.749 51.4243C192.509 50.1572 186.7 40.0946 191.088 28.6793C194.367 20.1514 202.539 16.9334 205.859 15.9524" stroke="#263238" stroke-miterlimit="10" />
        <path d="M206.583 15.7591C207.043 15.6402 207.302 15.5919 207.302 15.5919" stroke="#263238" stroke-miterlimit="10" />
        <path d="M213.186 13.537C212.24 10.6014 205.904 11.6568 207.139 15.4916C208.375 19.3301 214.132 16.4726 213.186 13.537Z" fill="#263238" />
        <path d="M203.693 12.3517C203.741 11.0623 205.529 10.0738 208.059 10.5309C210.589 10.9879 212.971 12.6935 212.971 12.6935C212.971 12.6935 210.47 14.2245 207.915 14.4957C205.359 14.767 203.649 13.6448 203.697 12.3517H203.693Z" fill="#EBEBEB" />
        <path d="M212.967 12.6936C211.654 12.701 210.344 12.6601 209.031 12.6192C207.722 12.5635 206.409 12.5115 205.103 12.4037C206.416 12.3926 207.725 12.4372 209.039 12.478C210.348 12.5338 211.661 12.5858 212.967 12.6936Z" fill="#263238" />
        <path d="M206.234 11.2109C206.613 11.3819 206.965 11.5974 207.317 11.8092C207.662 12.0359 208.011 12.2588 208.33 12.5264C207.952 12.3554 207.599 12.1399 207.247 11.9281C206.902 11.7014 206.553 11.4785 206.234 11.2109Z" fill="#263238" />
        <path d="M209.673 12.5747C209.339 12.8571 208.979 13.0949 208.619 13.3365C208.252 13.5631 207.885 13.7935 207.492 13.9793C207.826 13.6969 208.185 13.4591 208.545 13.2176C208.912 12.9909 209.28 12.7605 209.673 12.5747Z" fill="#263238" />
        <path d="M210.055 11.8463C210.252 11.9318 210.415 12.0581 210.578 12.1807C210.734 12.3145 210.89 12.4483 211.016 12.6229C210.819 12.5375 210.656 12.4111 210.493 12.2885C210.337 12.1547 210.181 12.021 210.055 11.8463Z" fill="#263238" />
        <path d="M206.104 19.9989C206.894 21.017 208.924 20.7718 210.708 18.9213C212.492 17.0707 213.427 14.2913 213.427 14.2913C213.427 14.2913 210.504 14.5142 208.274 15.7925C206.045 17.0708 205.31 18.9807 206.104 20.0026V19.9989Z" fill="#EBEBEB" />
        <path d="M213.427 14.2876C212.355 15.0494 211.32 15.852 210.277 16.6546C209.246 17.4684 208.215 18.2785 207.213 19.1294C208.285 18.3677 209.32 17.565 210.363 16.7624C211.394 15.9486 212.425 15.1385 213.427 14.2876Z" fill="#263238" />
        <path d="M208.831 19.4341C209.039 19.0737 209.198 18.6909 209.361 18.3119C209.51 17.9255 209.662 17.5427 209.762 17.1377C209.554 17.4981 209.395 17.8809 209.231 18.2599C209.083 18.6463 208.931 19.0291 208.831 19.4341Z" fill="#263238" />
        <path d="M210.823 16.3165C210.389 16.283 209.955 16.3016 209.524 16.3165C209.094 16.3462 208.66 16.3722 208.233 16.454C208.667 16.4874 209.101 16.4688 209.532 16.454C209.962 16.4242 210.396 16.3945 210.823 16.3165Z" fill="#263238" />
        <path d="M211.557 16.6807C211.668 16.4986 211.724 16.3016 211.787 16.1047C211.835 15.904 211.884 15.7034 211.884 15.4916C211.772 15.6737 211.717 15.8706 211.654 16.0675C211.605 16.2682 211.557 16.4689 211.557 16.6807Z" fill="#263238" />
        <path d="M213.186 13.537C212.603 13.7266 211.976 13.4033 211.787 12.8199C211.598 12.2365 211.921 11.6085 212.503 11.419C213.086 11.2295 213.713 11.5528 213.902 12.1362C214.091 12.7196 213.768 13.3475 213.186 13.537Z" fill="#455A64" />
        <path d="M213.868 15.6551C213.286 15.8446 212.659 15.5213 212.47 14.9379C212.281 14.3545 212.603 13.7265 213.186 13.537C213.768 13.3475 214.395 13.6708 214.584 14.2542C214.774 14.8376 214.451 15.4656 213.868 15.6551Z" fill="#455A64" />
        <path d="M92.252 14.7112C91.7215 19.7982 87.17 23.4918 82.0953 22.9605C80.044 22.7449 78.2189 21.8717 76.8056 20.5711L72.1798 21.2512L74.4945 17.2454C73.9344 15.878 73.697 14.3545 73.8602 12.7863C74.3907 7.69928 78.9423 4.00568 84.0169 4.53706C89.0952 5.06843 92.7825 9.62783 92.252 14.7112Z" fill="#262626" />
        <path d="M92.2519 14.7112C92.059 16.4019 91.4433 18.0592 90.3861 19.4044C90.1449 19.7574 89.8296 20.0509 89.5477 20.3705C89.225 20.6492 88.9282 20.9576 88.5721 21.1991C87.897 21.7194 87.1365 22.1281 86.3427 22.444C85.5526 22.7784 84.7031 22.9382 83.8536 23.0385C83.427 23.0608 82.9967 23.1017 82.5701 23.0645C82.1398 23.0534 81.7132 23.0088 81.2903 22.9307C79.5988 22.6223 77.9963 21.8308 76.7165 20.6678L76.8241 20.7012L72.202 21.4109L71.8682 21.463L72.0388 21.1657L74.3387 17.1525L74.3276 17.3123C73.2073 14.6703 73.4595 11.5192 74.8988 9.05931C75.6148 7.82563 76.6163 6.76288 77.796 5.9714C78.9756 5.1762 80.3407 4.67827 81.7429 4.48876C83.1451 4.30296 84.5844 4.4293 85.9235 4.87521C87.2589 5.32483 88.4868 6.08287 89.4884 7.06387C91.5286 9.00728 92.5561 11.9243 92.2556 14.7112H92.2519ZM92.2519 14.7112C92.5339 11.9205 91.4878 9.02215 89.4438 7.10475C88.4311 6.14604 87.2033 5.41773 85.879 4.99783C84.551 4.58165 83.134 4.48132 81.7651 4.68941C80.4 4.8975 79.0757 5.4103 77.9481 6.20178C76.8204 6.99699 75.8707 8.03372 75.1808 9.22281C74.4797 10.4119 74.1161 11.7756 74.0085 13.1431C73.9232 14.5142 74.1458 15.904 74.6577 17.1785L74.6911 17.2603L74.6466 17.3346L72.317 21.3329L72.1501 21.0877L76.7796 20.4374L76.8426 20.4299L76.8871 20.4745C78.1113 21.6265 79.6581 22.4291 81.3126 22.7598C81.7243 22.8416 82.1435 22.8973 82.5664 22.9122C82.9893 22.9568 83.4085 22.927 83.8313 22.9122C84.6697 22.8267 85.5118 22.6855 86.3019 22.3659C87.092 22.0687 87.8599 21.6785 88.5313 21.162C88.8874 20.9279 89.1842 20.6232 89.5069 20.3445C89.7925 20.0286 90.1041 19.7388 90.349 19.3895C91.4099 18.0555 92.0368 16.4056 92.2408 14.7149L92.2519 14.7112Z" fill="#263238" />
        <path d="M78.5378 18.0629C78.5378 17.5093 78.9199 17.1117 79.443 17.1117C79.9957 17.1117 80.3481 17.5093 80.3481 18.0629C80.3481 18.6166 79.9957 19.0142 79.443 19.0142C78.8902 19.0142 78.5378 18.598 78.5378 18.0629ZM78.9199 15.8668L78.7048 8.49451H80.1774L79.9623 15.8668H78.9199Z" fill="url(#paint9_linear_379_2620)" />
        <path d="M82.0619 18.0629C82.0619 17.5093 82.444 17.1117 82.967 17.1117C83.5197 17.1117 83.8721 17.5093 83.8721 18.0629C83.8721 18.6166 83.5197 19.0142 82.967 19.0142C82.4143 19.0142 82.0619 18.598 82.0619 18.0629ZM82.444 15.8668L82.2288 8.49451H83.7015L83.4863 15.8668H82.444Z" fill="url(#paint10_linear_379_2620)" />
        <path d="M85.5896 18.0629C85.5896 17.5093 85.9717 17.1117 86.4947 17.1117C87.0474 17.1117 87.3998 17.5093 87.3998 18.0629C87.3998 18.6166 87.0474 19.0142 86.4947 19.0142C85.942 19.0142 85.5896 18.598 85.5896 18.0629ZM85.9717 15.8668L85.7565 8.49451H87.2292L87.0141 15.8668H85.9717Z" fill="url(#paint11_linear_379_2620)" />
    </g>
    <defs>
        <linearGradient id="paint0_linear_379_2620" x1="55.2645" y1="33.2386" x2="69.2271" y2="33.2386" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E5042D" />
            <stop offset="1" stop-color="#990022" />
        </linearGradient>
        <linearGradient id="paint1_linear_379_2620" x1="59.5229" y1="39.2101" x2="61.0327" y2="39.2101" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E5042D" />
            <stop offset="1" stop-color="#990022" />
        </linearGradient>
        <linearGradient id="paint2_linear_379_2620" x1="35.5669" y1="39.7935" x2="61.0401" y2="39.7935" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E5042D" />
            <stop offset="1" stop-color="#990022" />
        </linearGradient>
        <linearGradient id="paint3_linear_379_2620" x1="50.9095" y1="35.7432" x2="61.9267" y2="35.7432" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E5042D" />
            <stop offset="1" stop-color="#990022" />
        </linearGradient>
        <linearGradient id="paint4_linear_379_2620" x1="76.4235" y1="93.4138" x2="205.785" y2="93.4138" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E5042D" />
            <stop offset="1" stop-color="#990022" />
        </linearGradient>
        <linearGradient id="paint5_linear_379_2620" x1="92.7639" y1="42.1865" x2="132.348" y2="42.1865" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E5042D" />
            <stop offset="1" stop-color="#990022" />
        </linearGradient>
        <linearGradient id="paint6_linear_379_2620" x1="76.4235" y1="93.4138" x2="205.785" y2="93.4138" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E5042D" />
            <stop offset="1" stop-color="#990022" />
        </linearGradient>
        <linearGradient id="paint7_linear_379_2620" x1="92.7639" y1="42.1865" x2="132.348" y2="42.1865" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E5042D" />
            <stop offset="1" stop-color="#990022" />
        </linearGradient>
        <linearGradient id="paint8_linear_379_2620" x1="71.0446" y1="61.1228" x2="180.431" y2="130.273" gradientUnits="userSpaceOnUse">
            <stop offset="0.28" stop-color="#E5042D" />
            <stop offset="1" stop-color="#990022" />
        </linearGradient>
        <linearGradient id="paint9_linear_379_2620" x1="78.5378" y1="13.7562" x2="80.3444" y2="13.7562" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E5042D" />
            <stop offset="1" stop-color="#990022" />
        </linearGradient>
        <linearGradient id="paint10_linear_379_2620" x1="82.0619" y1="13.7562" x2="83.8721" y2="13.7562" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E5042D" />
            <stop offset="1" stop-color="#990022" />
        </linearGradient>
        <linearGradient id="paint11_linear_379_2620" x1="85.5896" y1="13.7562" x2="87.3998" y2="13.7562" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E5042D" />
            <stop offset="1" stop-color="#990022" />
        </linearGradient>
        <clipPath id="clip0_379_2620">
            <rect width="227" height="149" fill="white" />
        </clipPath>
    </defs>
</svg>


export const RedAddIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="8" />
    <path d="M8 12H16" stroke="#8A8A8A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 16V8" stroke="#8A8A8A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>

export const RedAdderssWalletIcon = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_0_1)" />
    <path d="M16.6667 14H12.6667" stroke="#DFDFEC" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22.6667 15.3133V16.6866C22.6667 17.0533 22.3734 17.3533 22 17.3666H20.6933C19.9733 17.3666 19.3134 16.84 19.2534 16.12C19.2134 15.7 19.3734 15.3066 19.6534 15.0333C19.9 14.78 20.24 14.6333 20.6134 14.6333H22C22.3734 14.6466 22.6667 14.9466 22.6667 15.3133Z" stroke="#DFDFEC" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M19.6533 15.0334C19.3733 15.3067 19.2133 15.7 19.2533 16.12C19.3133 16.84 19.9733 17.3667 20.6933 17.3667H22V18.3334C22 20.3334 20.6667 21.6667 18.6667 21.6667H12.6667C10.6667 21.6667 9.33334 20.3334 9.33334 18.3334V13.6667C9.33334 11.8534 10.4267 10.5867 12.1267 10.3734C12.3 10.3467 12.48 10.3334 12.6667 10.3334H18.6667C18.84 10.3334 19.0067 10.34 19.1667 10.3667C20.8867 10.5667 22 11.84 22 13.6667V14.6334H20.6133C20.24 14.6334 19.9 14.78 19.6533 15.0334Z" stroke="#DFDFEC" stroke-linecap="round" stroke-linejoin="round" />
    <defs>
        <linearGradient id="paint0_linear_0_1" x1="0" y1="16" x2="32" y2="16" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E5042D" />
            <stop offset="1" stop-color="#990022" />
        </linearGradient>
    </defs>
</svg>


export const BackArrowIcon = <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.08 0.993413C6.75333 0.666746 6.22666 0.666746 5.9 0.993413L0.359997 6.53341C0.0999971 6.79341 0.0999971 7.21341 0.359997 7.47341L5.9 13.0134C6.22666 13.3401 6.75333 13.3401 7.08 13.0134C7.40666 12.6867 7.40666 12.1601 7.08 11.8334L4.37172 9.1214C3.20153 7.94959 3.20219 6.05122 4.37319 4.88022L7.08666 2.16675C7.40666 1.84675 7.40666 1.31341 7.08 0.993413Z" fill="white" fill-opacity="0.5" />
</svg>

export const CheckMarkIcon = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.75 11L9.58 13.83L15.25 8.16998" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>

