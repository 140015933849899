import * as React from "react";
import "./toast.scss";
import { CloseOutlined } from '@ant-design/icons';
import toast from "react-hot-toast";

type Props = {
    t: any
    type: 'success' | 'error' | 'warn' | 'info',
    title?: string,
    description?: string
};

export const Toast: React.FC<Props> = ({ t, type, title, description }) => {

    const icon = () => {
        switch (type) {
            case 'success':
                return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="48" height="48" rx="10" fill="#2AC769" />
                    <path
                        d="M24.6991 10.1394L33.9915 13.2571C35.0316 13.6045 35.7349 14.56 35.7406 15.6308L35.7998 24.9277C35.8181 27.7461 34.7906 30.4795 32.909 32.6211C32.0436 33.6041 30.9344 34.4484 29.5179 35.2034L24.5229 37.8736C24.3664 37.956 24.1945 37.9986 24.0211 38C23.8478 38.0013 23.6744 37.9601 23.5194 37.8791L18.4778 35.2707C17.0458 34.528 15.9267 33.6961 15.0529 32.7268C13.1403 30.6072 12.0776 27.8862 12.0593 25.0636L12 15.7736C11.9944 14.7014 12.6851 13.739 13.7196 13.3779L22.9767 10.149C23.5264 9.95406 24.1395 9.94994 24.6991 10.1394ZM29.1427 20.1061C28.7269 19.7066 28.0575 19.7094 27.6473 20.1144L22.9314 24.7628L21.0004 22.9067C20.5846 22.5072 19.9166 22.5113 19.505 22.9163C19.0949 23.3213 19.0991 23.9721 19.5149 24.3716L22.197 26.9525C22.4056 27.1529 22.6762 27.2518 22.9469 27.2491C23.2175 27.2477 23.4867 27.1461 23.6924 26.9429L29.1512 21.5613C29.5613 21.1563 29.5571 20.5056 29.1427 20.1061Z"
                        fill="white" />
                </svg>
            case 'warn':
                return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="48" height="48" rx="10" fill="#F6A609" />
                    <path
                        d="M24 38C16.258 38 10 31.7266 10 24C10 16.2706 16.258 10 24 10C31.728 10 38 16.2706 38 24C38 31.7266 31.728 38 24 38ZM24 19.8966C24.672 19.8966 25.218 19.3506 25.218 18.6786C25.218 18.0066 24.672 17.4466 23.986 17.4466C23.314 17.4466 22.768 18.0066 22.768 18.6786C22.768 19.3506 23.314 19.8966 24 19.8966ZM24 30.538C24.672 30.538 25.232 29.9766 25.232 29.306L25.232 23.118C25.232 22.4446 24.672 21.9 24 21.9C23.328 21.9 22.782 22.4446 22.782 23.118L22.782 29.306C22.782 29.9766 23.328 30.538 24 30.538Z"
                        fill="white" />
                </svg>
            case 'info':
                return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="48" height="48" rx="10" fill="url(#paint0_linear_0_1)" />
                    <path
                        d="M24 38C16.258 38 10 31.7266 10 24C10 16.2706 16.258 10 24 10C31.728 10 38 16.2706 38 24C38 31.7266 31.728 38 24 38ZM24 19.8966C24.672 19.8966 25.218 19.3506 25.218 18.6786C25.218 18.0066 24.672 17.4466 23.986 17.4466C23.314 17.4466 22.768 18.0066 22.768 18.6786C22.768 19.3506 23.314 19.8966 24 19.8966ZM24 30.538C24.672 30.538 25.232 29.9766 25.232 29.306L25.232 23.118C25.232 22.4446 24.672 21.9 24 21.9C23.328 21.9 22.782 22.4446 22.782 23.118L22.782 29.306C22.782 29.9766 23.328 30.538 24 30.538Z"
                        fill="white" />
                    <defs>
                        <linearGradient id="paint0_linear_0_1" x1="-13.5105" y1="2.69178e-06" x2="58.2718" y2="6.82133"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#3F62FE" />
                            <stop offset="1" stop-color="#678AF5" />
                        </linearGradient>
                    </defs>
                </svg>
            case 'error':
                return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="48" height="48" rx="10" fill="#FB4E4E" />
                    <path
                        d="M24 38C16.258 38 10 31.7266 10 24C10 16.2706 16.258 10 24 10C31.728 10 38 16.2706 38 24C38 31.7266 31.728 38 24 38ZM24 19.8966C24.672 19.8966 25.218 19.3506 25.218 18.6786C25.218 18.0066 24.672 17.4466 23.986 17.4466C23.314 17.4466 22.768 18.0066 22.768 18.6786C22.768 19.3506 23.314 19.8966 24 19.8966ZM24 30.538C24.672 30.538 25.232 29.9766 25.232 29.306L25.232 23.118C25.232 22.4446 24.672 21.9 24 21.9C23.328 21.9 22.782 22.4446 22.782 23.118L22.782 29.306C22.782 29.9766 23.328 30.538 24 30.538Z"
                        fill="white" />
                </svg>
            default:
                break;
        }
    }

    return (
        <>

            <div className={`ns-toast ${type}`}>
                <div className="alert-box">
                    <div className="alert-right-side">
                        <div className="alert-icon">
                            {icon()}
                        </div>
                        <div className="alert-content">
                            {title && <span className="title">{title}</span>}
                            {/* <span className="message">{message}</span> */}
                        </div>
                    </div>
                    <div className="alert-left-side">
                        <CloseOutlined onClick={() => toast.dismiss(t.id)} />
                    </div>

                </div>
            </div>


        </>
    );
};



export class message {

    static success(message: string = '', description: string = '') {
        toast.custom((t) => {
            return <Toast title={message} t={t} description={description} type='success' />
        })
    }

    static error(message: string = '', description: string = '') {
        toast.custom((t) => {
            return <Toast title={message} t={t} description={description} type='error' />
        })
    }

    static warn(message: string = '', description: string = '') {
        toast.custom((t) => {
            return <Toast title={message} t={t} description={description} type='warn' />
        })
    }

    static info(message: string = '', description: string = '') {
        toast.custom((t) => {
            return <Toast title={message} t={t} description={description} type='info' />
        })
    }

}
