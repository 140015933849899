import * as React from 'react';
import './swiper.scss';
import { CheckMarkIcon, SwiperArrowsIcon } from 'assets/icons/svgs/icons';
import { useSpring, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import { Spin } from 'antd';
import { CheckmarkIcon } from 'react-hot-toast';


interface SwiperButtonProps {
    onConfirm: () => any;
    loading: boolean,
    setLoading: any,
    locked: boolean
}
export const SwiperButton: React.FC<SwiperButtonProps> = ({ onConfirm, loading, setLoading, locked }) => {
    const [confirmed, setConfirmed] = React.useState(false);
    const sliderRef = React.useRef<HTMLDivElement>(null);
    const [{ x }, api] = useSpring(() => ({ x: 0 }));

    const bind = useDrag(

        ({ down, movement: [mx], direction: [dx], velocity }) => {
            if (locked) return;
            if (!down && (dx > 0 || parseInt(velocity.toString()) > 0.2)) {
                if (sliderRef.current && mx > sliderRef.current.offsetWidth * 0.8) {
                    setLoading(true);
                    var success = onConfirm();
                    if (success) {
                        setLoading(false);
                        setConfirmed(true);
                    }
                    else {
                        setLoading(false);
                        setConfirmed(false);
                    }

                }
            }
            api.start({ x: down ? mx : confirmed ? sliderRef.current!.offsetWidth - 40 : 0 });
        },
        { axis: 'x' }
    );

    return (
        <div className={`swiper-button ${locked ? 'disabled' : ''} ${confirmed ? 'checked' : ''}`} ref={sliderRef}>
            {
                confirmed === false ? <>
                    {SwiperArrowsIcon}
                    <animated.div  {...bind()} className="swiper-thumb text-center" style={{ x }} >
                        <div className="swiper-text">
                            {confirmed ? 'تایید شد' : 'برای انتقال به راست بکشید'}
                        </div>
                    </animated.div>

                </> : loading ? <Spin /> : CheckMarkIcon
            }
        </div>
    );
};

