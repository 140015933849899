import * as React from 'react';
import './widthrawal.scss';
import { Input } from 'components/input/input';
import { BlueScanIcon, ThreeDotsIcon } from 'assets/icons/svgs/icons';
import { SwiperButton } from 'components/swiper/swiper';

type Props = {

}

export const Widthrawal: React.FC<Props> = (props) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [addressWallet, setAddressWallet] = React.useState<string>();
    const [memo, setMemo] = React.useState<string>();


    const handlePasteClick = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setAddressWallet(text);
        } catch (error) {
            console.error('Failed to read clipboard contents:', error);
        }
    };

    const handleScanClick = () => {
        // Check if the QR scanner is available
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.scanQr) {
            window.Telegram.WebApp.scanQr()
                .then(({ data }) => {
                    // Handle scanned data
                    console.log("Scanned QR Code Data:", data);
                })
                .catch(err => {
                    // Handle errors (e.g., user canceled, permission denied)
                    console.error("Error scanning QR code:", err);
                });
        } else {
            console.error("QR scanner not available");
        }
    };
    const coin = {
        name: 'ترون',
        symbol: 'TRX',

    }
    return (
        <>

            <div className={`ns-widthrawal`}>
                <div className="main-content">
                    <div className="coin-info">
                        <h2>انتقال ارز {coin.name}</h2>
                        <p>
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است
                        </p>
                    </div>

                    <div className="destination-address-info">
                        <Input
                            value={addressWallet}
                            onChange={(e) => setAddressWallet(e.target.value)}
                            autoFocus={true}
                            dir='rtl'
                            icon={<>
                                <div onClick={() => handleScanClick()} className="scan">
                                    {BlueScanIcon}
                                </div>
                                <span onClick={() => handlePasteClick()}>جای گذاری</span>
                            </>}
                            placeholder='آدرس یا نام'
                            name='addressWallet'
                            type='text'
                        />
                        <Input
                            value={memo}
                            onChange={(e) => setMemo(e.target.value)}
                            autoFocus={true}
                            dir='rtl'

                            placeholder='ممو'
                            name='memo'
                            type='text'
                        />
                    </div>
                    <div className="recents">
                        <h4>اخیر</h4>
                        <div className="recent-transaction-box">
                            <div className="right-side">
                                <img src={'/exchanges/tetherland.png'} alt="" />
                                <div className="exchange-info">
                                    <span>تترلند</span>
                                    <span>{'jguui...gsad'.toUpperCase()}</span>
                                </div>
                            </div>
                            <div className="left-side">
                                {ThreeDotsIcon}
                                <span>۱۲ اردیبهشت</span>
                            </div>
                        </div>
                        <div className="recent-transaction-box">
                            <div className="right-side">
                                <img src={'/exchanges/pmxchange.jpeg'} alt="" />
                                <div className="exchange-info">
                                    <span>پی ام ایکسچنج</span>
                                    <span>{'dkjci...gsad'.toUpperCase()}</span>
                                </div>
                            </div>
                            <div className="left-side">
                                {ThreeDotsIcon}
                                <span>۱۲ اردیبهشت</span>
                            </div>
                        </div>
                    </div>

                    <SwiperButton
                        locked={addressWallet === ''}
                        loading={loading}
                        setLoading={setLoading}
                        onConfirm={() => true}
                    />

                </div>
            </div >
        </>
    );

};
