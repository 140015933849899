import { __API as API } from 'apis/api';
import { message } from 'components/toast/toast';
import { AddressWallet, MineAddressWallets, OrderList } from 'core/domains';
import { Page } from 'core/domains/commons/pagging';
import { getViaAuth, postViaAuth, responseValidator } from 'utils/scripts/api';


export const generateNewAddressWallet = (
    setloader: any,
    setData: any
) => {
    setloader(true);

    postViaAuth<AddressWallet>(API.wallets.create, {}).then((response: any) => {
        setloader(false);
        if (responseValidator(response.status)) {
            setData(response.value);
        }
    });
};


export const addNewSavedWallet = (
    setloader: any,
    setDrawer: any,
    addressWallet: string,
    label: string
) => {
    setloader(true);
    postViaAuth(API.wallets.add, { walletAddress: addressWallet, label: label }).then((response: any) => {
        setloader(false);
        if (responseValidator(response.status)) {
            setloader(false)
            setDrawer(false)
            //setData(response.value);
        }
    });
};



export const getMineAddressWallets = (
    setloader: any,
    setData: any
) => {
    setloader(true);
    getViaAuth<MineAddressWallets[]>(API.wallets.mines).then((response: any) => {
        setloader(false);
        if (responseValidator(response.status)) {
            setData(response.value);
        }
    });
};


