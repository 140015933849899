import { __API as API } from 'apis/api';
import { message } from 'components/toast/toast';
import { OrderList } from 'core/domains';
import { Page } from 'core/domains/commons/pagging';
import { getViaAuth, postViaAuth, responseValidator } from 'utils/scripts/api';

// export const createOrders = (
//     setmodal: any,
//     setloader: any,
//     market: Market,
//     amount: number,
//     side: Side,
//     clearInputs: any,
// ) => {
//     setloader(true);
//     // postViaAuth(API.orders.create, {
//     //     baseSymbol: market.symbol,
//     //     quoteSymbol: 'IRT',
//     //     side: side === Side.Buy ? 1 : 2,
//     //     sourceAmount: Number.parseFloat(amount.toString().replaceAll(',', '')),
//     // }).then((response: any) => {
//     //     setloader(false);
//     //     if (responseValidator(response.status)) {
//     //         message.success('سفارش با موفقیت ثبت شد.');
//     //         setmodal(false);
//     //         clearInputs();
//     //     } else {
//     //         setmodal(false);
//     //     }
//     // });
// };

export const getMineOrders = (
    setloader: any,
    setData: any
) => {
    setloader(true);

    getViaAuth<OrderList>(
        API.orders.mine,
    ).then((response: any) => {
        setloader(false);
        if (responseValidator(response.status)) {
            setData(response.value.items);
        }
    });
};
