import * as React from 'react';
import './needswap.scss';
import 'styles/global.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './dashboard/dashboard';
import { CoinsList } from 'components/coins-list/coins-list';
import { CreditCard } from 'components/credit-card/credit-card';
import { Coin } from './asset/coin';
import { SignIn } from './user/signin/signin';
import { SignUp } from './user/signup/signup';
import { Deposit } from './exchange/deposit/deposit';
import { TransactionsHistory } from './history/history';
import { Divider } from 'antd';
import { Navbar } from 'components/navbar/navbar';
import { Profile } from './user/profile/profile';
import { Swap } from './exchange/swap/swap';
import { Game } from './game/game';
import { Widthrawal } from './exchange/widthrawal/widthrawal';
import { SellPm } from './exchange/sell-pm/sell-pm';
import TelegramLogin from 'components/telegram/login/telegram-login';
import { SwapBox } from 'components/swapbox/swapbox';

export const NeedSwap: React.FC = () => {




    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/signin"
                    element={<SignIn />}
                />
                <Route
                    path="/signup"
                    element={<SignUp />}
                />
                <Route
                    path="/swap"
                    element={<Dashboard title="صرافی" component={<Swap />} />}
                />
                <Route
                    path="/game"
                    element={<Dashboard title="بازی" component={<Game />} />}
                />
                <Route
                    path="/history"
                    element={<Dashboard title="تاریخچه تراکنش ها" component={<TransactionsHistory />} />}
                />
                <Route
                    path="/profile"
                    element={<Dashboard title="حساب کاربری" component={<Profile />} />}
                />
                <Route
                    path="deposit/:symbol"
                    element={<Dashboard title="واریز ارز" component={<Deposit />} />}
                />
                <Route
                    path="widthrawal/:symbol"
                    element={<Dashboard title="برداشت ارز" component={<Widthrawal />} />}
                />
                <Route
                    path="coin/:symbol"
                    element={<Dashboard title="ارز" component={<Coin />} />}
                />
                <Route
                    path="pm-to-tron"
                    element={<Dashboard title="تبدیل ووچر پرفکت مانی به ترون" component={<SellPm />} />}
                />
                <Route
                    path="tron-to-pm"
                    element={<Dashboard title="تبدیل ترون به ووچر پرفکت مانی" component={<Deposit />} />}
                />
                <Route
                    path="usdt-to-pm"
                    element={<Dashboard title="تبدیل تتر به ووچر پرفکت مانی" component={<Deposit />} />}
                />
                <Route
                    path="/"
                    element={<Dashboard title="داشبورد" component={<>
                        <CreditCard />
                        <Divider />
                        <div className="swap-boxes">
                            <SwapBox url='deposit/usdt' source='تــتـر' destination='ووچـــــر' symbol='USDT' />
                            <SwapBox url='deposit/trx' source='ترون' destination='ووچـــــر' symbol='PM' />
                            <SwapBox url='pm-to-tron' source='ووچر' destination='تـــــرون' symbol='TRX' />
                        </div>
                    </>} />}
                />
            </Routes>
        </BrowserRouter>
    );

};
