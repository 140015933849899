import * as React from 'react';
import './credit-card.scss';
import tokens from 'assets/images/token.png'
import { AppstoreOutlined, DownloadOutlined, ReloadOutlined, SwapOutlined, UploadOutlined } from '@ant-design/icons';
import { HandDeposit, HandWithdraw, Swap } from '@phosphor-icons/react';
import { Box } from 'components/box/box';
import homeBanner from 'assets/images/home-banner.png'
import { NeedSwapDrawer } from 'components/drawer/drawer';
import { CoinsList } from 'components/coins-list/coins-list';
import { DepositIcon, SettingIcon, SwapIcon, WidthrawlIcon } from 'assets/icons/svgs/icons';
import { useNavigate } from 'react-router-dom';

type Props = {

}

export const CreditCard: React.FC<Props> = (props) => {

    const [depositIsOpen, setDepositIsOpen] = React.useState<boolean>(false);
    const [widthrawlIsOpen, setWidthrawlIsOpen] = React.useState<boolean>(false);
    const [swapIsOpen, setSwapIsOpen] = React.useState<boolean>(false);

    const navigate = useNavigate();
    return (
        <>
            <NeedSwapDrawer
                isOpen={depositIsOpen}
                setIsOpen={setDepositIsOpen}
                content={
                    <>
                        <CoinsList />
                    </>
                }
                title='ارز مورد نظر خود را انتخاب کنید'
            />
            <NeedSwapDrawer
                isOpen={widthrawlIsOpen}
                setIsOpen={setWidthrawlIsOpen}
                content={
                    <>
                        <CoinsList />
                    </>
                }
                title='ارز مورد نظر خود را انتخاب کنید'
            />
            <NeedSwapDrawer
                isOpen={swapIsOpen}
                setIsOpen={setSwapIsOpen}
                content={
                    <>
                        <CoinsList />
                    </>
                }
                title='ارز مورد نظر خود را انتخاب کنید'
            />
            <div className={`ns-credit-card rounded secondary`}>
                {/* <div className="balance text-center">
                    <h6 className='text-center'>موجودی حساب</h6>
                </div> */}
                {/* 
                <div className="wallet-options">
                    <Box
                        url='/settings'
                        icon={SettingIcon}
                        text='تنظیمات'
                    />
                    <Box
                        onClick={() => setSwapIsOpen(true)}
                        icon={SwapIcon}
                        text='تبدیل'
                    />
                    <Box
                        onClick={() => setDepositIsOpen(true)}
                        icon={DepositIcon}
                        text='واریز'
                    />
                    <Box
                        onClick={() => setWidthrawlIsOpen(true)}
                        icon={WidthrawlIcon}
                        text='برداشت'
                    />

                </div> */}
                <img onClick={() => navigate('pm-to-tron')} className='main-banner' src={homeBanner} />
            </div >
        </>
    );

};
