import * as React from 'react';
import './coins-list.scss';
import { Button } from 'components/button/button';
import btc from 'cryptocurrency-icons/svg/color/btc.svg'
import eth from 'cryptocurrency-icons/svg/color/eth.svg'
import ltc from 'cryptocurrency-icons/svg/color/ltc.svg'
import ada from 'cryptocurrency-icons/svg/color/ada.svg'
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { getIconPath } from 'utils/scripts/market';
import { COINS } from 'apis/constants/coins';

type Props = {
    title?: string,
}

export const CoinsList: React.FC<Props> = (props) => {

    const navigate = useNavigate();
    return (
        <>
            <div className={`ns-coins-list`}>
                {
                    props.title && <h5 className='text-right'>{props.title}</h5>
                }
                {
                    COINS.map((coin: any) => {
                        return <div onClick={() => navigate(`${coin.symbol === 'PM' ? 'pm-to-tron' : `deposit/${coin.symbol}`}`)} className='coin-item'>
                            <div className="right-side">
                                <img src={getIconPath(coin.symbol)} />
                                <div className="coin-info">
                                    <h4>{coin.name}</h4>
                                    {/* <span>${coin.price} <span className={`${coin.change < 0 ? 'low' : 'high'}`}>({coin.change > 0 ? '+' : ''}{coin.change}%)</span></span> */}
                                </div>
                            </div>
                            {/* <div className="left-side">
                                <div className="balance">
                                    <span>0</span> <span>{coin.symbol}</span>
                                </div>
                            </div> */}
                        </div>
                    })
                }
            </div >
        </>
    );

};
