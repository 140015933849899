import * as React from 'react';
import './deposit.scss';
import { Button } from 'components/button/button';
import btc from 'cryptocurrency-icons/svg/color/btc.svg'
import eth from 'cryptocurrency-icons/svg/color/eth.svg'
import ltc from 'cryptocurrency-icons/svg/color/ltc.svg'
import ada from 'cryptocurrency-icons/svg/color/ada.svg'
import usdt from 'cryptocurrency-icons/svg/color/usdt.svg'
import sol from 'cryptocurrency-icons/svg/color/sol.svg'
import { Box } from 'components/box/box';
import { CopyOutlined, DownloadOutlined, ShareAltOutlined, SwapOutlined, UploadOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Empty, message, Skeleton, Spin } from 'antd';
import { QRCode } from 'react-qrcode-logo';
import { RWebShare } from 'react-web-share';
import { Alert } from 'components/alert/alert';
import { CopyToClipboardIcon, ShareIcon } from 'assets/icons/svgs/icons';
import { generateNewAddressWallet } from 'core/repositories/wallets/wallet';
import { AddressWallet } from 'core/domains';
import { COINS } from 'apis/constants/coins';
import { getIconPath } from 'utils/scripts/market';


type Props = {

}


export const Deposit: React.FC<Props> = (props) => {

    const [coin, setCoin] = React.useState<any>();
    const [loading, setLoading] = React.useState<boolean>();
    const [wallet, setWallet] = React.useState<AddressWallet>();

    let location = useLocation();

    React.useEffect(() => {
        setCoin(COINS.filter((s: any) => s.symbol.toLowerCase() == location.pathname.split('/')[2].toLocaleLowerCase())[0])

        generateNewAddressWallet(setLoading, setWallet)
    }, [])

    const copyToClipboard = (value: string) => {
        navigator.clipboard.writeText(value)
        message.success('آدرس کیف پول کپی شد');
    }
    return (
        <>
            <div className={`ns-deposit`}>

                <div className="main-content">
                    {
                        loading ? <Spin style={{ "marginTop": "64px" }} spinning={true} /> :
                            coin && wallet && <>
                                <div className="coin-info">
                                    <h2>واریز ارز {coin.name}</h2>
                                    {
                                        wallet && <p>
                                            تنها توکن <span>{coin.symbol}</span> روی شبکه <span>{wallet.network}</span> را به این آدرس ارسال کنید، ارسال کوین های دیگر ممکن است منجر به از دست رفتن دارایی شما شود.
                                        </p>
                                    }
                                </div>
                                {
                                    wallet && <div
                                        onClick={() => copyToClipboard(wallet.walletAddress)}
                                        className="deposit-address-wallet">
                                        <QRCode size={148} logoHeight={32} logoWidth={32} logoImage={getIconPath(coin.symbol)} value="UQD9kh5nYQ_plwcOrNx9npJtrIRvT83-mta8WYHMTRrPi1gJ" />
                                        <p>{wallet.walletAddress}</p>
                                    </div>
                                }
                                {/* <p>
                                توجه کنید هر مقدار ترون به آدرس فوق واریز شود از طریق تلگرام و ایمیل ووچر برای شما به صورت اتوماتیک ارسال میشود.
                            </p> */}
                                <div className="more-info">

                                    <div className="shares">
                                        <div className="share-button">
                                            <Button icon={ShareIcon} type='dark' text='' />
                                        </div>
                                        <div onClick={() => copyToClipboard(wallet.walletAddress)} className="copy-button">
                                            <Button icon={CopyToClipboardIcon} type='dark' text='کپی آدرس' />
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>


            </div >
        </>
    );

};
