import * as React from 'react';
import './coin.scss';
import { Button } from 'components/button/button';
import btc from 'cryptocurrency-icons/svg/color/btc.svg'
import eth from 'cryptocurrency-icons/svg/color/eth.svg'
import ltc from 'cryptocurrency-icons/svg/color/ltc.svg'
import ada from 'cryptocurrency-icons/svg/color/ada.svg'
import usdt from 'cryptocurrency-icons/svg/color/usdt.svg'
import sol from 'cryptocurrency-icons/svg/color/sol.svg'
import { Box } from 'components/box/box';
import { DownloadOutlined, SwapOutlined, UploadOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { EmptyIcon } from 'assets/icons/svgs/icons';
import { Empty } from 'components/empty/empty';


type Props = {

}




const coins = [
    {
        id: 1,
        name: 'تتر',
        symbol: 'USDT',
        icon: <img src={usdt} />,
        balance: 0,
        usd: 0,
        change: +0.12,
        price: 1,
    },
    {
        id: 2,
        name: 'کاردانو',
        symbol: 'ADA',
        balance: 0,
        usd: 0,
        icon: <img src={ada} />,
        change: -3.21,
        price: 23.26,
    },
    {
        id: 3,
        name: 'لایت کوین',
        symbol: 'NOT',
        balance: 0,
        usd: 0,
        icon: <img src={ltc} />,
        change: +6.11,
        price: 134.62,
    },
    {
        id: 4,
        name: 'سولانا',
        symbol: 'SOL',
        balance: 0,
        usd: 0,
        icon: <img src={sol} />,
        change: -3.21,
        price: 7.13,
    },
    {
        id: 5,
        name: 'اتریوم',
        symbol: 'ETH',
        balance: 0,
        usd: 0,
        icon: <img src={eth} />,
        change: -2.61,
        price: 25204
    },
    {
        id: 6,
        name: 'بیتکوین',
        symbol: 'BTC',
        balance: 0,
        usd: 0,
        icon: <img src={btc} />,
        change: +5.91,
        price: 86301
    },
];
const transactions: any[] = [

]
export const Coin: React.FC<Props> = (props) => {

    const [coin, setCoin] = React.useState<any>();

    let location = useLocation();
    console.log(location);
    React.useEffect(() => {
        setCoin(coins.filter((s) => s.symbol.toLowerCase() == location.pathname.split('/')[2])[0])
    }, [])
    return (
        <>
            <div className={`ns-coin-asset`}>
                <div className="main-content">
                    {
                        coin && <>
                            <div className="coin-info">
                                {coin.icon}
                                <span><b>{coin.balance}</b> {coin.symbol}</span>
                                <span>~ {coin.usd} USD</span>
                            </div>
                            <div className="coin-actions">

                                <Box
                                    url='/swap'
                                    icon={<SwapOutlined size={32} color='white' />}
                                    text='تبدیل'
                                />

                                <Box
                                    url='/widthrawal'
                                    icon={<UploadOutlined size={32} color='white' />}
                                    text='برداشت'
                                />
                                <Box
                                    url={`/deposit/${coin.symbol.toLowerCase()}`}
                                    icon={<DownloadOutlined size={32} color='white' />}
                                    text='واریز'
                                />
                            </div>
                        </>
                    }
                </div>
                {
                    transactions.length > 0 ? transactions.map((coin) => {
                        return <div className='coin-item'>
                            <div className="right-side">
                                {coin.icon}
                                <div className="coin-info">
                                    <h5>{coin.name}</h5>
                                    <span>${coin.price} <span className={`${coin.change < 0 ? 'low' : 'high'}`}>({coin.change > 0 ? '+' : ''}{coin.change}%)</span></span>
                                </div>
                            </div>
                            <div className="left-side">
                                <div className="balance">
                                    <span>0</span> <span>{coin.symbol}</span>
                                </div>
                            </div>
                        </div>
                    }) :
                        <Empty description='تراکنشی یافت نشد.' />

                }
            </div >
        </>
    );

};
