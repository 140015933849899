import * as React from 'react';
import './signin.scss';
import { Input } from 'components/input/input';
import { Button } from 'components/button/button';

type Props = {

}

export const SignIn: React.FC<Props> = (props) => {

    const [userName, setUserName] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {

    }, [])
    return (
        <>
            <div className={`ns-signin`}>
                <div className="signin-form">

                    <Input
                        onChange={(e) => setUserName(e.target.value)}
                        value={userName}
                        placeholder='آدرس ایمیل'
                        label='نام کاربری'
                        type='text'
                        name='userName'
                    />

                    <Input
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        label='رمز عبور'
                        type='password'
                        name='password'
                    />

                    <Button
                        type='error'
                        loading={loading}
                        onClick={() => setLoading(true)}
                        text='ورود به نید سواپ'
                    />
                </div>
            </div>
        </>
    );

};
