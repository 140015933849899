import * as React from 'react';
import './box.scss';
import { useNavigate } from 'react-router-dom';


type Props = {
    text: string,
    url?: string,
    icon: any,
    onClick?: any,
}

export const Box: React.FC<Props> = (props) => {

    const navigator = useNavigate();

    return (
        <>
            <div onClick={() => props.url ? navigator(`${props.url}`) : props.onClick()} className={`ns-box`}>
                <div className="box-content">
                    {props.icon}
                    <span>{props.text}</span>
                </div>

            </div >

        </>
    );

};
