import * as React from 'react';
import './drawer.scss';
import { Drawer } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

interface Props {
    title?: any,
    isOpen: boolean;
    setIsOpen: any,
    content: any,
}

export const NeedSwapDrawer: React.FC<Props> = (props) => {

    React.useEffect(() => {
        console.log(props.isOpen)
    }, []);
    return (
        <Drawer
            className={`ns-drawer ${props.title && props.title !== '' ? '' : 'headerless'}`}
            open={props.isOpen}
            placement={"bottom"}
            onClose={() => props.setIsOpen(false)}
            closeIcon={<CloseCircleOutlined onClick={() => props.setIsOpen(false)} />}
            title={props.title}
        >
            {props.content}

        </Drawer>
    );
};
