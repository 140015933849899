
export const COINS: any[] = [
    {
        id: 1,
        name: 'پرفکت مانی',
        symbol: 'PM',
        change: +0.12,
        price: 1,
    },
    {
        id: 2,
        name: 'ترون',
        symbol: 'TRX',
        change: +0.12,
        price: 1,
    },
    {
        id: 3,
        name: 'تتر',
        symbol: 'USDT',
        change: +0.12,
        price: 1,
    }
]