import * as React from 'react';
import './empty.scss';
import { DepositIcon, EmptyIcon, SettingIcon, SwapIcon, WidthrawlIcon } from 'assets/icons/svgs/icons';

type Props = {
    description: string,
}

export const Empty: React.FC<Props> = (props) => {

    return (
        <>
            <div className="ns-empty">
                {EmptyIcon}
                <p>{props.description}</p>
            </div>
        </>
    );

};
