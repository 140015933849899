import * as React from 'react';
import './game.scss';
import { Headline } from 'components/headline/headline';

type Props = {

}
export const Game: React.FC<Props> = (props) => {


    return (
        <>
            <div className={`ns-game`}>
                <Headline title='بازی' description='از این قسمت بازی کنید و سکه جمع کنید، بزودی تکمیل خواهد شد.' />
            </div >
        </>
    );

};
