import { __API as API } from 'apis/api';
import { message } from 'components/toast/toast';
import { OrderList } from 'core/domains';
import { Page } from 'core/domains/commons/pagging';
import { getViaAuth, postViaAuth, responseValidator } from 'utils/scripts/api';

export const swapPmToCrypto = (
    setLoader: any,
    setDrawer: any,
    wallet: string,
    voucherCode: string,
    voucherActivationCode: string,
    clearInputs: any,
) => {
    setLoader(true);
    postViaAuth(API.orders.swaps.pmToCrypto, {
        wallet: wallet,
        voucherCode: voucherCode,
        voucherActivationCode: voucherActivationCode,
    }).then((response: any) => {
        setLoader(false);
        if (responseValidator(response.status)) {
            message.success('تبدیل با موفقیت انجام شد.');
            setDrawer(true);
            clearInputs();
            return true;
        } else {
            setDrawer(true);
            return false
        }
    });
};

