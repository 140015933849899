import * as React from 'react';
import './headline.scss';
import { ConfigProvider, Tooltip } from 'antd';
import { InfoIcon } from 'assets/icons/svgs/icons';

type Props = {
    title: string,
    description?: string
}
export const Headline: React.FC<Props> = (props) => {


    return (
        <>
            <div className={`ns-headline`}>
                {
                    props.description ? <ConfigProvider>
                        <Tooltip placement="bottomLeft" title={props.description}>
                            <h2>{props.title} {InfoIcon}</h2>
                        </Tooltip>
                    </ConfigProvider> :
                        <h2>{props.title}</h2>
                }
            </div >
        </>
    );

};
