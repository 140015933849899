import * as React from 'react';
import './navbar.scss';
import { ContactsOutlined, MessageOutlined, ScanOutlined } from '@ant-design/icons';
import { QrReader } from 'react-qr-reader';
import moment from 'jalali-moment'
import { Avatar } from 'antd';
import { en2Fa } from 'utils/scripts/string';
import { useLocation, useNavigate } from 'react-router-dom';
type Props = {

}

export const Navbar: React.FC<Props> = (props) => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <div className={`ns-navbar`}>
                <div className="head-line">
                    <div onClick={() => location.pathname !== '/profile' && navigate('/profile', { replace: true })} className="profile">
                        <div className="avatar">
                            <Avatar shape='square' children={<span>{localStorage.getItem('name')?.toString()[0].toUpperCase()}</span>} />
                        </div>
                        <span className='name'>{localStorage.getItem('name')}</span>
                    </div>

                    <span className='today'>
                        {en2Fa(moment().locale('fa').format('dddd D MMMM YYYY'))}
                    </span>
                </div>

                {/* <QrReader
                    constraints={{

                    }}
                    onResult={(result, error) => {
                        if (!!result) {
                            setData(result);
                        }

                        if (!!error) {
                            console.info(error);
                        }
                    }}
                    videoStyle={{ width: '100%' }}
                /> */}

            </div >
        </>
    );

};
