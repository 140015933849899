import { __API as API } from 'apis/api';
import { message } from 'components/toast/toast';
import { AddressWallet, OrderList } from 'core/domains';
import { Page } from 'core/domains/commons/pagging';
import { UserProfile, UserSignUpModel } from 'core/domains/users/user';
import moment from 'moment';
import { getViaAuth, post, postGeneric, postViaAuth, responseValidator } from 'utils/scripts/api';


export const signUp = (
    setloader: any,
    setData: any,
    user: UserSignUpModel
) => {
    setloader(true);
    console.log('login user', user)

    postGeneric<UserProfile>(API.users.login, user).then((response: any) => {
        setloader(false);
        if (responseValidator(response.status)) {
            localStorage.setItem('name', response.value.fullName);
            localStorage.setItem('userid', user.chatId.toString());
            localStorage.setItem('email', user.email);
            localStorage.setItem('date', moment.utc().toString());
            localStorage.setItem('ref', response.value.refreshToken);
            localStorage.setItem('token', response.value.accessToken);
            localStorage.setItem('expires_in', response.value.expiresIn);
            setData(response.value);
        }
    });
};

export const updateEmail = (
    setloader: any,
    email: string
) => {
    setloader(true);
    postViaAuth(API.users.updateEmail, { email: email }).then((response: any) => {
        setloader(false);
        if (responseValidator(response.status)) {
            localStorage.setItem('email', email);
        }
    });
};


export const getMineProfile = (
    setloader: any,
    setData: any
) => {
    setloader(true);

    getViaAuth<UserProfile>(
        API.users.profile,
    ).then((response: any) => {
        setloader(false);
        if (responseValidator(response.status)) {
            setData(response.value);
        }
    });
};
