import * as React from 'react';
import './swapbox.scss';
import { getIconPath } from 'utils/scripts/market';
import { SwapIconLight } from 'assets/icons/svgs/icons';
import { useNavigate } from 'react-router-dom';


type Props = {
    symbol: string,
    source: string,
    destination: string,
    url: string
}

export const SwapBox: React.FC<Props> = ({ url, symbol, source, destination }) => {
    const navigator = useNavigate();

    return (
        <div onClick={() => navigator(url)} className={`ns-swapbox`}>
            {SwapIconLight}
            <span>تبدیل <b className='bold'>{source}</b> به <b className='bold'>{destination}</b></span>
        </div>
    );
};

