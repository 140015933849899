type apiParams = string | number;

export const __API = {
    token: 'general/signin/refresh-token',
    users: {
        login: 'user/signup',
        updateEmail: 'user/editemail',
        profile: 'user/getMineProfile'
    },
    orders: {
        mine: 'order/getMineOrders',
        swaps: {
            pmToCrypto: 'order/swapPmWithCrypto'
        }
    },
    wallets: {
        create: 'user/createWallet',
        add: 'userSavedWallet/add',
        mines: 'userSavedWallet/getMineWallets'
    },
    markets: {
        calculate: ''
    }
};
