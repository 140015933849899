import * as React from 'react';
import { useState } from 'react';
import './dashboard.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'core/redux/store';
import { APPNAME } from 'constants/site';
import { useAppDispatch } from 'core/redux/hooks';
import { Helmet } from 'react-helmet';
import { Box } from 'components/box/box';
import { HandDeposit, HandWithdraw, Swap } from '@phosphor-icons/react';
import { CreditCard } from 'components/credit-card/credit-card';
import { CoinsList } from 'components/coins-list/coins-list';
import { NavbarMenu } from 'components/menu/menu';
import { Navbar } from 'components/navbar/navbar';

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    component: React.ReactElement;
    title: string;
}

const Dashboard: React.FC<Props> = (props) => {
    const [title, setTitle] = React.useState<string>('');

    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const path = location.pathname.trim();
    React.useEffect(() => {
        // if (!isLogin()) navigate('/login');

        // SidebarItems.map((item, index) => {
        //     path.includes(item.link) &&
        //         path === item.link &&
        //         dispatch(setMenuItem(item.id));
        // });
    }, [location.pathname]);

    const [isLoading, setLoading] = useState<boolean>(false);


    React.useEffect(() => {
        if (location.pathname.split('/')[0].includes('signin')) setTitle('ورود');
        else setTitle(props.title);
    }, [location.pathname]);
    return (
        <>
            <Helmet>
                <title>
                    {title || ''} {title && '|'} {APPNAME}
                </title>
                <meta
                    name="description"
                    content="خرید و فروش اتوماتیک و آسان پرفکت مانی ، تتر و رمز ارز ها احراز هویت هوشمند و سریع زیر ۱ دقیقه بدون معطلی نرخ های رقابتی و بدون کارمزد"
                />
            </Helmet>
            <Navbar />
            <div className="ns-container">

                {!isLoading && (
                    <div
                        className={`ns-dashboard`}
                    >

                        {props.component}

                        <NavbarMenu />
                    </div>
                )}
            </div>
        </>
    );
};

export default connector(Dashboard);
